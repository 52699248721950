const Fleet = require("../MapData/Fleet");
const CustomMath = require("../../../Common/Math/CustomMath");
const ResolveData = require("../EndOfRound/ResolveData");
const GameGlobalFunctions = require("../../Other/GameGlobalFunctions");
const CombatFactionData = require("./CombatFactionData");
const CombatPack = require("./CombatPack");
const System = require("../MapData/System");
const Planet = require("../MapData/Planet");
const CombatData = require("./CombatData");
const LogBook = require("../Connection/LogBook");
const CombatRecording = require("./CombatRecording");
const Unit = require("../MapData/Unit");

class CombatHighFunctions {
  //To do
  static executeOnSpaceCombatParticipatingUnits(system, faction, func) {
    const units = CombatFactionData.getSpaceCombatParticipatingUnits(
      system,
      faction
    );
    for (let i = 0; i < units.length; i++) {
      func(units[i]);
    }
  }

  static executeOnGroundCombatParticipatingUnits(
    system,
    planet,
    faction,
    func
  ) {
    const units = CombatFactionData.getGroundCombatParticipatingUnits(
      system,
      planet,
      faction
    );
    for (let i = 0; i < units.length; i++) {
      func(units[i]);
    }
  }

  static executeOnFactionList(combatPack, callback) {
    const factionList = ResolveData.getAllPlayerData(
      CombatPack.getResolveData(combatPack)
    ).map((playerData) => playerData.faction.name);

    const system = CombatPack.getSystem(combatPack);
    const planet = CombatPack.getPlanet(combatPack);

    CustomMath.shuffleArray(factionList);

    let defendingFaction = system.faction;
    if (planet) {
      defendingFaction = planet.faction;
    }

    if (defendingFaction) {
      const index = factionList.indexOf(defendingFaction);
      if (index > -1) {
        factionList.splice(index, 1);
      }
      //insert the defending faction at the start
      factionList.unshift(defendingFaction);
    }

    for (let i = 0; i < factionList.length; i++) {
      const faction = factionList[i];
      callback(faction);
    }
  }

  static executeOnPlayerDataList(combatPack, callback) {
    const executeOnFaction = (faction) => {
      const playerData = ResolveData.getPlayerDataFromFaction(
        CombatPack.getResolveData(combatPack),
        faction
      );
      callback(playerData);
    };

    this.executeOnFactionList(combatPack, executeOnFaction);
  }

  static executeOnUnitsOnPlanets(combatPack, faction, callback) {
    const system = CombatPack.getSystem(combatPack);

    const planets = System.getPlanets(system);
    for (let i = 0; i < planets.length; i++) {
      const planet = planets[i];
      const fleets = Planet.getFleets(planet);
      for (let j = 0; j < fleets.length; j++) {
        const fleet = fleets[j];
        if (fleet.faction === faction) {
          const units = Fleet.getUnits(fleet);
          for (let k = 0; k < units.length; k++) {
            callback(units[k]);
          }
        }
      }
    }
  }

  static executeOnNonFighterShips(combatPack, faction, callback) {
    this.executeOnShips(combatPack, faction, (unit) => {
      if (unit.class !== Unit.UNIT_TYPE_FIGHTER) {
        callback(unit);
      }
    });
  }

  static executeOnShips(combatPack, faction, callback) {
    const system = CombatPack.getSystem(combatPack);
    const fleet = System.getFleet(system, faction);
    if (fleet) {
      const units = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
      for (let i = 0; i < units.length; i++) {
        callback(units[i]);
      }
    }
  }

  static resetCombatData(combatPack) {
    CombatData.initializeFleetCombatList(CombatPack.getCombatData(combatPack));
    CombatPack.setDLog(combatPack, LogBook.createLogBook());
    CombatPack.setHLog(combatPack, LogBook.createLogBook());
  }

  static resetLogs(combatPack) {
    CombatPack.setDLog(combatPack, LogBook.createLogBook());
    CombatPack.setHLog(combatPack, LogBook.createLogBook());
  }

  static addHLog(combatPack, text, items = []) {
    LogBook.generateAddMessage(CombatPack.getHLog(combatPack), text, items);
  }

  static addDLog(combatPack, text, items = []) {
    LogBook.generateAddMessage(CombatPack.getDLog(combatPack), text, items);
  }

  static addHits(combatPack, faction, hits) {
    const combatData = CombatPack.getCombatData(combatPack);
    const fleetCombat = CombatData.getFleetCombat(combatData, faction);
    fleetCombat.hits = CustomMath.roundDec(fleetCombat.hits + hits);
  }

  static concludeAddHits(combatPack, faction) {
    const combatData = CombatPack.getCombatData(combatPack);
    const fleetCombat = CombatData.getFleetCombat(combatData, faction);

    this.addHLog(
      combatPack,
      "$faction$ generated " + fleetCombat.hits + " hits.",
      [faction]
    );
  }

  static recordLogsIfDlogEntry(combatPack) {
    const DLog = CombatPack.getDLog(combatPack);
    if (LogBook.isEmpty(CombatPack.getDLog(combatPack))) {
      this.resetLogs(combatPack);
    }
    this.recordLog(combatPack);
  }

  static recordLog(combatPack) {
    CombatRecording.createStep(
      CombatPack.getRecData(combatPack),
      CombatPack.getHLog(combatPack),
      CombatPack.getDLog(combatPack),
      CombatPack.getSystem(combatPack)
    );
    this.resetLogs(combatPack);
  }

  static assignHits(
    combatPack,
    filtering = null,
    damageSource = null,
    context = []
  ) {
    CombatPack.getCombatFunctions(combatPack).assignHits(
      CombatPack.getRecData(combatPack),
      CombatPack.getResolveData(combatPack),
      CombatPack.getCombatData(combatPack),
      CombatPack.getSystem(combatPack),
      CombatPack.getPlanet(combatPack),
      filtering,
      damageSource,
      context
    );
  }
}

module.exports = CombatHighFunctions;
