const Unit = require("./Unit.js");
const Cost = require("../Utils/Cost.js");
const Color = require("../../../Common/Config/Colors.js");
const LogBook = require("../Connection/LogBook.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");

class Fleet {
  static UNIT_CLASS_SHIP = "ship";
  static UNIT_CLASS_GROUND_FORCE = "ground force";
  static UNIT_CLASS_STRUCTURE = "structure";
  static UNIT_CLASS_ALL = "all";

  static createFleet(playerData, factionName) {
    return {
      faction: factionName,
      color: Color.getColorFromFaction(playerData, factionName),
      ships: [],
      groundForces: [],
      structures: [],
    };
  }

  static createUnit(playerData, fleet, unitType, unitSubType = "") {
    const unit = Unit.createUnit(
      playerData,
      fleet.faction,
      unitType,
      unitSubType
    );
    this.addUnit(fleet, unit);
    return unit;
  }

  static addUnits(fleet, units) {
    units.forEach((unit) => {
      this.addUnit(fleet, unit, false);
    });
    this._sortUnitsByMass(fleet);
  }

  static addUnit(fleet, unit, sort = true) {
    if (Fleet.isUnitInFleet(fleet, unit)) {
      return;
    }

    const unitArray = Fleet.getUnitArray(fleet, unit.class);

    const unitTypeArray = unitArray.find(
      (u) => u.type === unit.type && u.subType === unit.subType
    );

    if (unitTypeArray) {
      unitTypeArray.units.push(unit);
    } else {
      unitArray.push({
        type: unit.type,
        subType: unit.subType,
        units: [unit],
      });
    }

    // Sort the array by mass after adding the unit
    if (sort) {
      Fleet._sortUnitsByMass(fleet, unit.class);
    }
  }

  //Units
  static getUnits(fleet, unitClass = Fleet.UNIT_CLASS_ALL) {
    if (!fleet) {
      return [];
    }
    const units = [];

    if (
      unitClass === Fleet.UNIT_CLASS_ALL ||
      unitClass === Fleet.UNIT_CLASS_SHIP
    ) {
      //console.log("getUnits fleet: ", fleet);
      //console.log("getUnits fleet.ships: ", fleet.ships);
      fleet.ships.forEach((ship) => {
        ship.units.forEach((unit) => {
          units.push(unit);
        });
      });
    }

    if (
      unitClass === Fleet.UNIT_CLASS_ALL ||
      unitClass === Fleet.UNIT_CLASS_GROUND_FORCE
    ) {
      fleet.groundForces.forEach((groundForce) => {
        groundForce.units.forEach((unit) => {
          units.push(unit);
        });
      });
    }

    if (
      unitClass === Fleet.UNIT_CLASS_ALL ||
      unitClass === Fleet.UNIT_CLASS_STRUCTURE
    ) {
      fleet.structures.forEach((structure) => {
        structure.units.forEach((unit) => {
          units.push(unit);
        });
      });
    }

    return units;
  }

  static removeUnits(fleet, units) {
    units.forEach((unit) => {
      this.removeUnit(fleet, unit);
    });
  }
  /*static removeDeadUnits(fleet) {
    const units = Fleet.getUnits(fleet);
    units.forEach((unit) => {
      if (Unit.getHP(unit) <= 0) {
        Fleet.removeUnit(fleet, unit);
      }
    });
  }*/

  static removeUnit(fleet, unit) {
    const unitArray = Fleet.getUnitArray(fleet, unit.class);

    const unitTypeArray = unitArray.find(
      (u) => u.type === unit.type && u.subType === unit.subType
    );

    if (unitTypeArray) {
      let indexToRemove = -1;
      indexToRemove = unitTypeArray.units.findIndex((u) =>
        Unit.areUnitsTheSame(u, unit)
      );

      if (indexToRemove === -1) {
        return false;
        //throw new Error("Fleet: Attempting to remove non-existent unit");
      } else {
        unitTypeArray.units.splice(indexToRemove, 1);
        if (unitTypeArray.units.length === 0) {
          const indexToRemove = unitArray.findIndex((u) => u === unitTypeArray);
          unitArray.splice(indexToRemove, 1);
        }
      }
      return true;
    }
    return false;
  }

  static getUnitArray(fleet, unitClass) {
    //console.log("getUnitArray fleet: ", fleet);
    //console.log("getUnitArray unitClass: ", unitClass);
    switch (unitClass) {
      case this.UNIT_CLASS_SHIP:
        return fleet.ships;
      case this.UNIT_CLASS_GROUND_FORCE:
        return fleet.groundForces;
      case this.UNIT_CLASS_STRUCTURE:
        return fleet.structures;
      case this.UNIT_CLASS_ALL:
        return fleet.ships.concat(fleet.groundForces).concat(fleet.structures);
      default:
        throw new Error("Fleet: Invalid unit class");
    }
  }

  static _sortUnitsByMass(fleet) {
    this._sortUnitsByMassForClass(fleet, this.UNIT_CLASS_SHIP);
    this._sortUnitsByMassForClass(fleet, this.UNIT_CLASS_GROUND_FORCE);
    this._sortUnitsByMassForClass(fleet, this.UNIT_CLASS_STRUCTURE);
  }

  static _sortUnitsByMassForClass(fleet, unitClass) {
    const unitArray = Fleet.getUnitArray(fleet, unitClass);

    // Sort the array based on the mass of the corresponding unit types
    unitArray.sort((unitTypeArrayA, unitTypeArrayB) => {
      const unitAMass = unitTypeArrayA.units[0].mass;
      const unitBMass = unitTypeArrayB.units[0].mass;

      return unitAMass - unitBMass;
    });

    //Sort each unitTypeArray per HP left, increasing
    unitArray.forEach((unitTypeArray) => {
      unitTypeArray.units.sort((unitA, unitB) => {
        return unitA.hp - unitB.hp;
      });
    });
  }

  static isUnitInFleet(fleet, unit) {
    if (!unit.id) {
      return false;
    }

    const units = Fleet.getUnits(fleet);
    return units.some((u) => Unit.areUnitsTheSame(u, unit));
  }

  //Fleet
  static addFleetToFleet(fleetFrom, fleetTo) {
    const units = Fleet.getUnits(fleetFrom);
    Fleet.addUnits(fleetTo, units);
  }

  static removeFleetFromFleet(fleetFrom, fleetTo) {
    const units = Fleet.getUnits(fleetFrom);
    Fleet.removeUnits(fleetTo, units);
  }

  static getUnitTypeData(unitTypeArray) {
    let hpSum = 0;
    let maxHpSum = 0;
    let hpOnMaxHP = 0;
    const amount = unitTypeArray.units.length;
    unitTypeArray.units.forEach((unit) => {
      hpSum += unit.hp;
      maxHpSum += unit.maxHp;
      hpOnMaxHP += unit.hp / unit.maxHp;
    });
    return {
      amount: amount,
      hpSum: hpSum,
      maxHpSum: maxHpSum,
      hpOnMaxHP: hpOnMaxHP,
    };
  }

  static getFleetClassShortText(fleet, unitClass) {
    const unitClassArray = Fleet.getUnitArray(fleet, unitClass);

    let text = "";

    unitClassArray.forEach((unitTypeArray) => {
      const unitTypeData = Fleet.getUnitTypeData(unitTypeArray);
      if (unitClass !== Fleet.UNIT_CLASS_STRUCTURE) {
        text += `${unitTypeData.amount} ${Unit.getTypeAbbreviation(
          unitTypeArray.type
        )} ${unitTypeData.hpSum}/${unitTypeData.maxHpSum} , `;
      } else {
        text += `${unitTypeData.amount} ${Unit.getTypeAbbreviation(
          unitTypeArray.type
        )} , `;
      }
    });

    //Remove last comma
    text = text.slice(0, -3);

    return text;
  }

  static getFilteredUnits(fleet, filtering) {
    let units = Fleet.getUnits(fleet);

    if (filtering) {
      if (filtering.types) {
        units = units.filter((unit) => filtering.types.includes(unit.type));
      }
      if (filtering.classes) {
        units = units.filter((unit) => filtering.classes.includes(unit.class));
      }
    }
    return units;
  }

  static getFleetClassSuperShortText(fleet, unitClass) {
    const unitClassArray = Fleet.getUnitArray(fleet, unitClass);

    let text = "";

    unitClassArray.forEach((unitTypeArray) => {
      const unitTypeData = Fleet.getUnitTypeData(unitTypeArray);
      text += `${unitTypeData.amount}${Unit.getSuperShortAbbreviation(
        unitTypeArray.type
      )} `;
    });

    text = text.trim();
    text += ".";

    return text;
  }

  //Display fleet
  static getMapDisplayData(fleet) {
    const ships = Fleet.getUnitArray(fleet, Fleet.UNIT_CLASS_SHIP);
    const dotsData = [];
    ships.forEach((shipTypeArray) => {
      if (shipTypeArray.units.length > 0 && shipTypeArray.units[0].mass > 0) {
        dotsData.push({
          amount: shipTypeArray.units.length,
          mass: shipTypeArray.units[0].mass,
          label: Unit.getSuperShortAbbreviation(shipTypeArray.units[0].type),
        });
      }
    });

    return dotsData;
  }

  static UnitDisplaySlots() {
    const slots = [];

    /*const addUnitsType = (units) => {
      if (units.length <= 0) return;
      for (let i = 0; i < slots.length; i++) {
        if (slots[i].type === units[0].type) {
          const slotUnits = slots[i].units;
          for (let j = 0; j < units.length; j++) {
            slotUnits.push(units[j]);
          }
        }
      }
    };*/
    const addEmptySlot = (type, x, y) => {
      slots.push({ type: type, x: x, y: y, units: [] });
    };

    const getSlotForType = (type) => {
      for (let i = 0; i < slots.length; i++) {
        if (slots[i].type === type) {
          return slots[i];
        }
      }
      return null;
    };

    const addFleet = (fleet) => {
      const unitTypeArrays = Fleet.getUnitArray(fleet, Fleet.UNIT_CLASS_ALL);

      for (let j = 0; j < unitTypeArrays.length; j++) {
        const unitTypeArray = unitTypeArrays[j];

        //console.log("unitTypeArray : ", unitTypeArray);
        if (unitTypeArray.units.length > 0) {
          if (unitTypeArray.units[0].class !== Fleet.UNIT_CLASS_STRUCTURE) {
            // Avoid printing structure that way

            const slotForType = getSlotForType(unitTypeArray.units[0].type);
            slotForType.units.push([]);

            for (let k = 0; k < unitTypeArray.units.length; k++) {
              slotForType.units[slotForType.units.length - 1].push(
                unitTypeArray.units[k]
              );
            }
          }
        }
      }
    };

    const orderFromBiggestToSmallest = () => {
      slots.sort((a, b) => {
        return b.units.length - a.units.length;
      });
    };

    const splitStack = () => {
      let didUnstackchange = true;
      const slotLocal = slots;
      while (didUnstackchange) {
        didUnstackchange = false;
        orderFromBiggestToSmallest();
        let indexSlotToFill = slots.length - 1;
        for (let i = 0; i < slots.length; i++) {
          const slotToUnstack = slots[i];

          if (slots[indexSlotToFill].units.length > 0) {
            return;
          }

          /*if (
            slotToUnstack.type !== Unit.UNIT_TYPE_INFANTRY &&
            slotToUnstack.type !== Unit.UNIT_TYPE_FIGHTER &&
            slotToUnstack.type !== Unit.UNIT_TYPE_MECH
          ) {*/
          if (slotToUnstack.units.length > 1) {
            for (
              let j = 0;
              j < Math.floor(slotToUnstack.units.length / 2);
              j++
            ) {
              const slotUnitsToFill = slots[indexSlotToFill].units;
              slotUnitsToFill.push(slotToUnstack.units.pop());
              didUnstackchange = true;
            }
            indexSlotToFill--;
            //}
          }
        }
      }
    };

    return {
      getSlots: slots,
      addFleet: addFleet,
      splitStack: splitStack,
      addEmptySlot: addEmptySlot,
    };
  }

  static getUnitSelectionArray(fleet, unitClasses = null) {
    if (unitClasses === null) {
      throw new Error(
        "Fleet: getUnitSelectionArray requires unitClasses to be defined"
      );
    }
    if (fleet === null) return [];
    const unitSelectionArray = [];
    unitClasses.forEach((unitClass) => {
      const units = Fleet.getUnits(fleet, unitClass);
      units.forEach((unit) => {
        unitSelectionArray.push({ unit: unit, selected: false });
      });
    });
    return unitSelectionArray;
  }

  static getTotalMorale(
    playerData,
    fleet,
    unitClass = null,
    logBookToRecord = null
  ) {
    if (unitClass === null) {
      throw new Error(
        "Fleet: getTotalMorale requires unitClasses to be defined"
      );
    }
    if (fleet === null) return 0;
    let totalMorale = 0;

    const units = Fleet.getUnits(fleet, unitClass);
    units.forEach((unit) => {
      let unitEffectifMorale = unit.morale;
      if (
        Unit.getHP(playerData, unit) !== undefined &&
        Unit.getMaxHP(playerData, unit) !== undefined &&
        Unit.getHP(playerData, unit) &&
        Unit.getMaxHP(playerData, unit)
      ) {
        unitEffectifMorale = CustomMath.roundDec(
          unit.morale *
            (Unit.getHP(playerData, unit) / Unit.getMaxHP(playerData, unit))
        );
        if (logBookToRecord) {
          LogBook.generateAddMessage(
            logBookToRecord,
            "$unit$ has a base morale of " +
              unit.morale +
              " -> ponderated by %HP left : " +
              unitEffectifMorale +
              ".",
            [unit]
          );
        }
      } else {
        if (logBookToRecord) {
          LogBook.generateAddMessage(
            logBookToRecord,
            "$unit$ has a base morale of " + unit.morale + ".",
            [unit]
          );
        }
      }

      totalMorale += unitEffectifMorale;
    });

    return CustomMath.roundDec(totalMorale);
  }

  static getTotalMass(fleet, unitClass = null) {
    if (unitClass === null) {
      throw new Error("Fleet: getTotalMass requires unitClasses to be defined");
    }
    if (fleet === null) return 0;
    let totalMass = 0;

    const units = Fleet.getUnits(fleet, unitClass);
    units.forEach((unit) => {
      totalMass += unit.mass;
    });

    return totalMass;
  }

  static getTotalHP(playerData, fleet, unitClass = Fleet.UNIT_CLASS_ALL) {
    if (fleet === null) return 0;
    let totalHP = 0;

    const units = Fleet.getUnits(fleet, unitClass);
    units.forEach((unit) => {
      if (Unit.getHP(playerData, unit) !== undefined) {
        totalHP += Unit.getHP(playerData, unit);
      }
    });

    return totalHP;
  }

  static isEmpty(fleet) {
    if (fleet === null) return true;
    return (
      fleet.ships.length === 0 &&
      fleet.groundForces.length === 0 &&
      fleet.structures.length === 0
    );
  }

  static includeShips(fleet) {
    return fleet.ships.length > 0;
  }

  static includeGroundForces(fleet) {
    return fleet.groundForces.length > 0;
  }

  static includeStructures(fleet) {
    return fleet.structures.length > 0;
  }

  //Get

  static getUnitFromId(fleet, unitId) {
    const units = Fleet.getUnits(fleet);
    return units.find((unit) => unit.id === unitId);
  }

  static getLogItem(fleet, unitClass = Fleet.UNIT_CLASS_ALL) {
    const fleetCopy = JSON.parse(JSON.stringify(fleet));
    if (unitClass === Fleet.UNIT_CLASS_SHIP) {
      fleetCopy.groundForces = [];
      fleetCopy.structures = [];
    }
    if (unitClass === Fleet.UNIT_CLASS_GROUND_FORCE) {
      fleetCopy.ships = [];
      fleetCopy.structures = [];
    }
    if (unitClass === Fleet.UNIT_CLASS_STRUCTURE) {
      fleetCopy.ships = [];
      fleetCopy.groundForces = [];
    }

    return fleetCopy;
  }

  static getTotalCapacity(playerData, fleet) {
    const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
    if (ships.length === 0) return 0;
    let totalCapacity = 0;
    ships.forEach((ship) => {
      if (Unit.getCapacity(playerData, ship)) {
        totalCapacity += Unit.getCapacity(playerData, ship);
      }
    });
    return totalCapacity;
  }

  static getTotalRequiredCapacity(playerData, fleet) {
    const groundForces = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE);

    let totalRequiredCapacity = 0;

    groundForces.forEach((groundForce) => {
      if (Unit.getRequiredCapacity(playerData, groundForce)) {
        totalRequiredCapacity += Unit.getRequiredCapacity(
          playerData,
          groundForce
        );
      }
    });

    const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
    ships.forEach((ship) => {
      if (Unit.getRequiredCapacity(playerData, ship)) {
        totalRequiredCapacity += Unit.getRequiredCapacity(playerData, ship);
      }
    });

    if (TechTree.hasPlayerTech(playerData, TechList.TECH.modularBay.name)) {
      let fighterNotCountingMax = 0;
      const nonFigherShips = Fleet.getUnits(
        fleet,
        Fleet.UNIT_CLASS_SHIP
      ).filter((unit) => unit.type !== Unit.UNIT_TYPE_FIGHTER);
      if (nonFigherShips.length > 0) {
        fighterNotCountingMax = 3;
      }
      const fighters = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP).filter(
        (unit) => unit.type === Unit.UNIT_TYPE_FIGHTER
      );

      const reduceRequiredCapacity = Math.min(
        fighterNotCountingMax,
        fighters.length
      );
      totalRequiredCapacity -= reduceRequiredCapacity;
    }

    return totalRequiredCapacity;
  }

  static getUnitFromType(fleet, unitType, unitClass = Fleet.UNIT_CLASS_ALL) {
    const units = Fleet.getUnits(fleet, unitClass);
    const resultUnits = [];
    for (let i = 0; i < units.length; i++) {
      if (units[i].type === unitType) {
        resultUnits.push(units[i]);
      }
    }
    return resultUnits;
  }

  static executeUnitAbility(
    playerData,
    fleet,
    abilityId,
    callback,
    unitClass = Fleet.UNIT_CLASS_ALL
  ) {
    const units = Fleet.getUnits(fleet, unitClass);
    for (let i = 0; i < units.length; i++) {
      Unit.executeUnitAbility(playerData, units[i], abilityId, callback);
    }
  }

  static getUnitsWithAbility(
    playerData,
    fleet,
    abilityId,
    unitClass = Fleet.UNIT_CLASS_ALL
  ) {
    const units = Fleet.getUnits(fleet, unitClass);
    const unitWithAbilityList = [];
    for (let i = 0; i < units.length; i++) {
      const unit = units[i];
      const unitWithAbility = Unit.getUnitWithAbility(
        playerData,
        unit,
        abilityId
      );
      if (unitWithAbility) {
        unitWithAbilityList.push(unitWithAbility);
      }
    }
    return unitWithAbilityList;
  }

  static filteredClass(fleet, unitClass) {
    const fleetCopy = JSON.parse(JSON.stringify(fleet));
    fleetCopy.groundForces = [];
    fleetCopy.structures = [];
    fleetCopy.ships = [];

    if (unitClass === Fleet.UNIT_CLASS_SHIP) {
      Fleet.addUnits(fleetCopy, Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP));
    }
    if (unitClass === Fleet.UNIT_CLASS_GROUND_FORCE) {
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE)
      );
    }
    if (unitClass === Fleet.UNIT_CLASS_STRUCTURE) {
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE)
      );
    }

    return fleetCopy;
  }

  static excludeClass(fleet, unitClass) {
    const fleetCopy = JSON.parse(JSON.stringify(fleet));
    fleetCopy.groundForces = [];
    fleetCopy.structures = [];
    fleetCopy.ships = [];
    if (unitClass === Fleet.UNIT_CLASS_SHIP) {
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE)
      );
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE)
      );
    }
    if (unitClass === Fleet.UNIT_CLASS_GROUND_FORCE) {
      Fleet.addUnits(fleetCopy, Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP));
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_STRUCTURE)
      );
    }
    if (unitClass === Fleet.UNIT_CLASS_STRUCTURE) {
      Fleet.addUnits(
        fleetCopy,
        Fleet.getUnits(fleet, Fleet.UNIT_CLASS_GROUND_FORCE)
      );
      Fleet.addUnits(fleetCopy, Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP));
    }

    return fleetCopy;
  }

  static getLogisticMass(fleet) {
    return Fleet.getTotalMass(fleet, Fleet.UNIT_CLASS_SHIP);
    /*const ships = Fleet.getUnits(fleet, Fleet.UNIT_CLASS_SHIP);
    let totalLogisticMass = 0;
    ships.forEach((ship) => {
      if (!ship.transportRequired) {
        totalLogisticMass += ship.mass;
      }
    });
    return totalLogisticMass;*/
  }

  static checkCapacity(playerData, fleet) {
    if (!fleet) return;
    let fleetCapacity = Fleet.getTotalCapacity(playerData, fleet);
    let fleetRequiredCapacity = Fleet.getTotalRequiredCapacity(
      playerData,
      fleet
    );

    if (fleetRequiredCapacity > fleetCapacity) {
      throw new Error(
        "The total required capacity of your ships is " +
          fleetRequiredCapacity +
          ". Your fleet capacity is " +
          fleetCapacity +
          ". You can't have a total required capacity higher than your fleet capacity."
      );
    }
  }

  static getCost(playerData, fleet) {
    const factionName = playerData.faction.name;
    const units = Fleet.getUnits(fleet);
    const cost = Cost.createCost({});
    units.forEach((unit) => {
      const unitCatalog = Unit.createUnit(
        playerData,
        factionName,
        unit.type,
        unit.subType,
        false
      );
      Cost.addCostToCost(Unit.getCost(playerData, unitCatalog), cost);
    });
    return cost;
  }

  static checkValidity(playerData, fleet) {
    const capacity = Fleet.getTotalCapacity(playerData, fleet);
    const requiredCapacity = Fleet.getTotalRequiredCapacity(playerData, fleet);

    if (capacity < requiredCapacity) {
      throw new Error(
        "Fleet has not enough capacity to transport all units. The required capacity is " +
          requiredCapacity +
          " and the total capacity is " +
          capacity +
          "."
      );
    }
  }

  static getFleetFromFleets(fleets, faction) {
    //Get a fleet from a system
    let fleetIndex = fleets.findIndex((fleet) => fleet.faction === faction);
    if (fleetIndex == -1) {
      return null;
    } else {
      if (Fleet.isEmpty(fleets[fleetIndex])) {
        // This check is needed because during the update of the global map, some system may have empty fleets
        fleets.splice(fleetIndex, 1);
        return null;
      }
      return fleets[fleetIndex];
    }
  }

  static getFleetOrCreateFromFleets(playerData, fleets, faction) {
    let fleet = this.getFleetFromFleets(fleets, faction);
    if (!fleet) {
      fleet = Fleet.createFleet(playerData, faction);
      fleets.push(fleet);
    }
    return fleet;
  }
}

module.exports = Fleet;
