const Unit = require("../MapData/Unit");

const TechTree = require("../Technology/TechTree");
const TechList = require("../Technology/TechList");
const Map = require("../MapData/Map");
const MapUnitaryAction = require("../UnitaryAction/MapUnitaryAction");
const Tech = require("../Technology/Tech");
const Fleet = require("../MapData/Fleet");
const System = require("../MapData/System");
const ResolveData = require("../EndOfRound/ResolveData");
const CombatData = require("../Combat/CombatData");
const LogBook = require("../Connection/LogBook");
const SolveFleetLimitData = require("../MandatoryAction/SolveFleetLimitData");
const SolveCapacityData = require("../MandatoryAction/SolveCapacityData");
const CombatRecording = require("../Combat/CombatRecording");
const PlayerData = require("../PlayerData/PlayerData");
const Cost = require("../Utils/Cost");
const SecondaryObject = require("../MapData/SecondaryObject");
const ScoreData = require("../Objectifs/ScoreData");
const MinorFaction = require("../MinorFaction/MinorFaction");
const Request = require("../../../Common/Requests/Request");
const AbilityCommon = require("./AbilityCommon");
const Phase = require("../Game/Phase");
const StaticGameData = require("../StaticGameData");
const Faction = require("../PlayerData/Faction");
const ResearchActionData = require("../ActionData/ResearchActionData");
const UnitUnitaryAction = require("../UnitaryAction/UnitUnitaryAction");
const ServiceUnitaryAction = require("../UnitaryAction/ServiceUnitaryAction");
const Planet = require("../MapData/Planet");
const UIMessage = require("../Connection/UIMessage");
const Item = require("../Transactions/Item");
const PlanetBonus = require("../Bonus/PlanetBonus");
const HeroNames = require("../MinorFaction/HeroNames");
const PlayerEffect = require("../Faction/PlayerEffect");
const FactionAbility = require("../Faction/FactionAbility");
const CombatHighFunctions = require("../Combat/CombatHighFunctions");
const CombatPack = require("../Combat/CombatPack");
const AbilityCard = require("./AbilityCard");
const PlayerRememberAction = require("../PlayerData/PlayerRememberAction");
const CustomMath = require("../../../Common/Math/CustomMath");

class ResolveFactionAbility {
  //static PHASE_TECH_ABILITY = "resolveTechAbility";

  static sendToServerCommon(abilityCardName, actionData) {
    const playerData = StaticGameData.getPlayerData();
    const data = {
      abilityCardName: abilityCardName,
      data: actionData,
    };

    playerData.phase = AbilityCommon.PHASE_RESOLVE_ABILITY;
    playerData.step = Phase.STEP_FACTION_ABILITY;

    Request.updateGameState(data);
  }

  static resolveCommon = (playerData, updateData) => {
    const abilityCardName = updateData.abilityCardName;
    const data = updateData.data;

    if (
      FactionAbility.hasFactionAbility(playerData, abilityCardName) === false
    ) {
      throw new Error(
        "You do not have this faction ability : " + abilityCardName
      );
    }

    const abilityCard = FactionAbility.getFactionAbilityCard(
      playerData,
      abilityCardName
    );
    if (!abilityCard) {
      throw new Error(
        "You do not have this faction ability : " + abilityCardName
      );
    }

    if (AbilityCard.isExhausted(abilityCard)) {
      throw new Error(
        "This ability is exhausted, you cannot use it for now. It will be readied at the beginning of the next round."
      );
    }

    AbilityCard.exhaust(abilityCard);

    this.routeAbility(playerData, abilityCard, data);
  };

  static gainVP = (playerData, system, minorFaction) => {
    const scoreData = PlayerData.getScoreData(playerData);
    ScoreData.addScore(
      scoreData,
      minorFaction.vp,
      "Action of " + minorFaction.name + " in the system " + system.name + "."
    );
    MinorFaction.resetVP(minorFaction);
  };

  static routeAbility = (playerData, abilityCard, data) => {
    switch (abilityCard.name) {
      case FactionAbility.LITHORIAN_COLLECTIVE_NATURAL_ROCK_FACTORIES:
        this.rocksNaturalRockFactories(playerData, abilityCard, data);
        break;

      case FactionAbility.ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM:
        this.planetaryAssaultPlatform(playerData, abilityCard, data);
        break;

      default:
        throw new Error("Unknown faction ability");
    }
  };

  static rocksNaturalRockFactories = (playerData, abilityCard, data) => {
    const planet = Map.getSpaceObjectFromName(playerData.map, data.planetName);

    const unit = Unit.createUnit(
      playerData,
      playerData.faction.name,
      Unit.UNIT_TYPE_FACTORY
    );
    if (planet.faction !== playerData.faction.name) {
      throw new Error("You can only place a factory on a planet you control.");
    }
    MapUnitaryAction.placeUnitsOnPlanet(playerData, planet, [unit]);

    PlayerData.spendCost(playerData, Cost.createCost({ mineral: 5 }));

    this.logAndMessage(
      playerData,
      abilityCard,
      "Placed a factory on " + planet.name + " for 5 minerals."
    );
  };

  static logAndMessage = (playerData, abilityCard, message) => {
    UIMessage.sendInfoMessageToClient(playerData, abilityCard.name, message);
    PlayerData.generateLogActivity(
      playerData,
      abilityCard.name + " : " + message
    );
  };

  static resolveDominusLegionPreemptiveBarrage(combatPack) {
    CombatHighFunctions.resetCombatData(combatPack);

    const executeOnPlayerData = (playerData) => {
      if (
        FactionAbility.hasFactionAbility(
          playerData,
          FactionAbility.DOMINUS_LEGION_PREEMPTIVE_BARRAGE
        )
      ) {
        CombatHighFunctions.addHLog(
          combatPack,
          "$faction$ uses " +
            FactionAbility.DOMINUS_LEGION_PREEMPTIVE_BARRAGE +
            " ability.",
          [playerData.faction.name]
        );
        CombatHighFunctions.executeOnNonFighterShips(
          combatPack,
          playerData.faction.name,
          (unit) => {
            const hits = CombatPack.getCombatFunctions(combatPack).rollOnce(
              CustomMath.roundDec(Unit.getMinDamage(playerData, unit) + 0.3),
              CustomMath.roundDec(Unit.getMaxDamage(playerData, unit) + 0.3)
            );
            if (hits > 0) {
              CombatHighFunctions.addHits(
                combatPack,
                playerData.faction.name,
                hits
              );
              CombatHighFunctions.addDLog(
                combatPack,
                "$unit$ uses " +
                  FactionAbility.DOMINUS_LEGION_PREEMPTIVE_BARRAGE +
                  " and generates " +
                  hits +
                  " hits.",
                [unit]
              );
            }
          }
        );

        CombatHighFunctions.concludeAddHits(
          combatPack,
          playerData.faction.name
        );

        CombatHighFunctions.recordLogsIfDlogEntry(combatPack);

        CombatHighFunctions.assignHits(combatPack);
      }
    };

    CombatHighFunctions.executeOnPlayerDataList(
      combatPack,
      executeOnPlayerData
    );
  }

  static resolveEtheralEmbush(combatPack) {
    CombatHighFunctions.resetCombatData(combatPack);

    const executeOnPlayerData = (playerData) => {
      if (
        FactionAbility.hasFactionAbility(
          playerData,
          FactionAbility.ETHERAL_SPREAD_ETHERAL_EMBUSK
        )
      ) {
        CombatHighFunctions.addHLog(
          combatPack,
          "$faction$ uses " +
            FactionAbility.ETHERAL_SPREAD_ETHERAL_EMBUSK +
            " ability.",
          [playerData.faction.name]
        );
        CombatHighFunctions.executeOnUnitsOnPlanets(
          combatPack,
          playerData.faction.name,
          (unit) => {
            if (unit.type === Unit.UNIT_TYPE_INFANTRY) {
              //console.log("DEBUG resolveEtheralEmbush : ");
              const hits = CombatPack.getCombatFunctions(combatPack).rollOnce(
                Unit.getMinDamage(playerData, unit),
                Unit.getMaxDamage(playerData, unit)
              );
              if (hits > 0) {
                CombatHighFunctions.addHits(
                  combatPack,
                  playerData.faction.name,
                  hits
                );
                CombatHighFunctions.addDLog(
                  combatPack,
                  "$unit$ uses " +
                    FactionAbility.ETHERAL_SPREAD_ETHERAL_EMBUSK +
                    " and generates " +
                    hits +
                    " hits.",
                  [unit]
                );
              }
            }
          }
        );

        CombatHighFunctions.concludeAddHits(
          combatPack,
          playerData.faction.name
        );

        CombatHighFunctions.recordLogsIfDlogEntry(combatPack);

        CombatHighFunctions.assignHits(combatPack);
      }
    };

    CombatHighFunctions.executeOnPlayerDataList(
      combatPack,
      executeOnPlayerData
    );
  }

  static resolveStartOfRound(playerData) {
    const controlledPlanets = Map.getPlanetsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    const controlledSystems = Map.getSystemsFromFaction(
      playerData.map,
      playerData.faction.name
    );

    //ETHERAL_ETHERAL_CONNECTION
    if (
      FactionAbility.hasFactionAbility(
        playerData,
        FactionAbility.ETHERAL_SPREAD_ETHERAL_CONNECTION
      )
    ) {
      const energyControlled = controlledPlanets.reduce(
        (acc, planet) => acc + Planet.getEnergyProduction(playerData, planet),
        0
      );
      if (energyControlled >= 10) {
        PlayerEffect.applyEffect(
          playerData,
          PlayerEffect.ETHERAL_ETHERAL_CONNECTION
        );
        PlayerData.generateLogActivity(
          playerData,
          FactionAbility.ETHERAL_SPREAD_ETHERAL_CONNECTION +
            " : Gained the " +
            PlayerEffect.ETHERAL_ETHERAL_CONNECTION +
            " effect thanks to controlling planets for a combined energy production of at least 10."
        );
      }
    }

    //natural mineral sensor
    if (
      FactionAbility.hasFactionAbility(
        playerData,
        FactionAbility.LITHORIAN_COLLECTIVE_MINERAL_SENSOR
      )
    ) {
      const amountMineralGainedByNMS = controlledSystems.length * 0.5;
      PlayerData.gainMineral(playerData, amountMineralGainedByNMS);
      PlayerData.generateLogActivity(
        playerData,
        FactionAbility.LITHORIAN_COLLECTIVE_MINERAL_SENSOR +
          " : Gained " +
          amountMineralGainedByNMS +
          " minerals for controlling " +
          controlledSystems.length +
          " systems."
      );
    }

    //Very rich
    if (
      FactionAbility.hasFactionAbility(
        playerData,
        FactionAbility.GLOBAL_VERY_RICH
      )
    ) {
      const amount = 2;
      PlayerData.gainCredit(playerData, amount);
      PlayerData.generateLogActivity(
        playerData,
        FactionAbility.GLOBAL_VERY_RICH + " : Gained " + amount + " credits."
      );
    }

    //Hyper powered
    if (
      FactionAbility.hasFactionAbility(
        playerData,
        FactionAbility.GLOBAL_HYPER_POWERED
      )
    ) {
      const amount = 2;
      PlayerData.gainEnergy(playerData, amount);
      PlayerData.generateLogActivity(
        playerData,
        FactionAbility.GLOBAL_HYPER_POWERED + " : Gained " + amount + " energy."
      );
    }

    //Ready actilities
    FactionAbility.readyAllFactionAbilities(playerData);
  }

  static veilOfImmunity = (recData, resolveData, system, planet) => {
    //Defendor
    let defenderFaction = system.faction;
    if (planet) {
      defenderFaction = planet.faction;
    }

    //If no defendor, no need to resolve
    if (!defenderFaction) {
      return;
    }

    //if denfender, defender playerData
    const defenderPlayerData = ResolveData.getPlayerDataFromFaction(
      resolveData,
      defenderFaction
    );

    //if defendor does not have the ability
    if (
      !FactionAbility.hasFactionAbility(
        defenderPlayerData,
        FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY
      )
    ) {
      return;
    }

    const playerDataList = ResolveData.getAllPlayerData(resolveData);
    for (let i = 0; i < playerDataList.length; i++) {
      const playerData = playerDataList[i];

      if (defenderFaction !== playerData.faction.name) {
        //If space combat
        if (!planet) {
          if (
            PlayerRememberAction.hasAttackedFactionInSpaceInSystem(
              playerData.rememberAction,
              defenderFaction,
              system
            )
          ) {
            //Loosing population
            const amountPositive = 1;
            PlayerData.gainPopulation(playerData, -amountPositive);
            //Logging in playerDara
            PlayerData.generateLogActivity(
              playerData,
              "Loosing " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability in the system " +
                system.name +
                ".",
              ["population"]
            );
            //Logging
            const HLog = LogBook.generateLogBook(
              "$faction$ looses " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability in the system " +
                system.name +
                ".",
              [playerData.faction.name, "population"]
            );
            const DLog = LogBook.generateLogBook(
              "$faction$ looses " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability in the system " +
                system.name +
                ".",
              [playerData.faction.name, "population"]
            );
            CombatRecording.createStep(recData, HLog, DLog, system);
          }
        } else {
          if (
            PlayerRememberAction.hasAttackedFactionOnGroundOnPlanet(
              playerData.rememberAction,
              defenderFaction,
              planet
            )
          ) {
            //Loosing population
            const amountPositive = 1;
            PlayerData.gainPopulation(playerData, -amountPositive);
            //Logging in playerDara
            PlayerData.generateLogActivity(
              playerData,
              "Loosing " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability on the planet " +
                planet.name +
                ".",
              ["population"]
            );
            //Logging
            const HLog = LogBook.generateLogBook(
              "$faction$ looses " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability on the planet " +
                planet.name +
                ".",
              [playerData.faction.name, "population"]
            );
            const DLog = LogBook.generateLogBook(
              "$faction$ looses " +
                amountPositive +
                " $logo$ because attacking a faction with the " +
                FactionAbility.CONCORDANT_ORDER_VEIL_OF_IMMUNITY +
                " ability on the planet " +
                planet.name +
                ".",
              [playerData.faction.name, "population"]
            );
            CombatRecording.createStep(recData, HLog, DLog, system);
          }
        }
      }
    }
  };

  //ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM:
  static planetaryAssaultPlatform = (playerData, abilityCard, data) => {
    const targetPlanet = Map.getSpaceObjectFromName(
      playerData.map,
      data.planetName
    );
    const targetSystem = Map.getSystemFromSpaceObjectName(
      playerData.map,
      targetPlanet.name
    );

    const adjacentSystems = Map.getAdjacentSystems(
      playerData,
      targetSystem,
      playerData.map,
      false
    );

    let validity = false;
    for (let i = 0; i < adjacentSystems.length; i++) {
      const system = adjacentSystems[i];
      const planets = System.getPlanets(system);
      for (let j = 0; j < planets.length; j++) {
        const planet = planets[j];

        const fleet = Planet.getFleet(planet, playerData.faction.name);
        if (fleet) {
          const units = Fleet.getUnits(fleet);
          if (units.some((unit) => unit.type === Unit.UNIT_TYPE_FACTORY)) {
            validity = true;
          }
        }
      }
    }

    if (!validity) {
      throw new Error("You do not have a factory on an adjacent system.");
    }

    MapUnitaryAction.placeUnitsOnPlanet(playerData, targetPlanet, [
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_INFANTRY
      ),
      Unit.createUnit(
        playerData,
        playerData.faction.name,
        Unit.UNIT_TYPE_INFANTRY
      ),
    ]);
  };

  static subversionProtocol(map, allPlayerDataList) {
    for (let i = 0; i < allPlayerDataList.length; i++) {
      const playerData = allPlayerDataList[i];
      if (
        FactionAbility.hasFactionAbility(
          playerData,
          FactionAbility.CONCORDANT_ORDER_SUBVERSION_PROTOCOL
        )
      ) {
        const controlledPlanets = Map.getPlanetsFromFaction(
          map,
          playerData.faction.name
        );

        const potentialTargetPlanets = [];

        for (let j = 0; j < controlledPlanets.length; j++) {
          const controlledPlanet = controlledPlanets[j];

          const system = Map.getSystemFromSpaceObjectName(
            map,
            controlledPlanet.name
          );
          const adjacentSystems = Map.getAdjacentSystems(
            playerData,
            system,
            map,
            false
          );

          for (let k = 0; k < adjacentSystems.length; k++) {
            const adjacentSystem = adjacentSystems[k];
            const planets = System.getPlanets(adjacentSystem);
            for (let l = 0; l < planets.length; l++) {
              const planet = planets[l];

              let planetValid = true;
              /*const fleets = Planet.getFleets(planet);
         
              for (let m = 0; m < fleets.length; m++) {
                const fleet = fleets[m];
                if (fleet.faction !== playerData.faction.name) {
                  if (!Fleet.isEmpty(fleet)) {
                    planetValid = false;
                  }
                }
              }*/

              if (planet.faction) {
                planetValid = false;
              }

              if (planetValid) {
                potentialTargetPlanets.push(planet);
              }
            }
          }
        }

        if (potentialTargetPlanets.length > 0) {
          CustomMath.shuffleArray(potentialTargetPlanets);
          const targetPlanet = potentialTargetPlanets[0];
          Planet.takeControl(playerData, playerData.faction.name, targetPlanet);

          PlayerData.generateLogActivity(
            playerData,
            FactionAbility.CONCORDANT_ORDER_SUBVERSION_PROTOCOL +
              " : Gained control of the planet " +
              targetPlanet.name +
              "."
          );
        }
      }
    }
  }
}

module.exports = ResolveFactionAbility;
