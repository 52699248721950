import React, { useState } from "react";
import { GameListView } from "../GameList/GameList";
import TestSimpleComponent from "../Test/TestSimpleComponent";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalLayout from "../GameView/GameGlobalLayout";
import NavigationView from "../GameView/NavigationView";
import MenuNavigationView from "./MenuNavigationView";
import BaseButton from "../GameView/Utils/BaseButton";
import LineSpace from "../GameView/Utils/Layout/LineSpace";
import Popup from "../Data/Other/Popup";

export const ProfileView = () => {
  return (
    <div>
      <h1>Profile</h1>
      <div>Username : {StaticGameData.username}</div>
      <div>
        This screen will provide information about your achievements and
        ranking. Will be available soon.
      </div>
    </div>
  );
};

export default ProfileView;
