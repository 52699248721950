const LogBook = require("../Connection/LogBook.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Cost = require("../Utils/Cost.js");
const Color = require("../../../Common/Config/Colors.js");
const AbilityCommon = require("../Ability/AbilityCommon.js");
const UnitAbility = require("../Ability/UnitAbility.js");
const TechTree = require("../Technology/TechTree.js");
const TechList = require("../Technology/TechList.js");

class SecondaryObject {
  static SECONDARY_OBJECT_MOON = "moon";
  static SECONDARY_OBJECT_WORMHOLE = "wormhole";
  static SECONDARY_OBJECT_NONE = "void";
  static SECONDARY_OBJECT_MINOR_FACTION = "minor_faction";
  static SECONDARY_OBJECT_OBJECTIVE = "objective";

  static createVoid() {
    // Implementation for creating a void space object
    return {
      type: "void",
      name: null,
      // Add other properties as needed
    };
  }

  static getMinorFaction(secondaryObject) {
    return secondaryObject.minorFactionData; //Create map sets this up
  }

  static getObjectiveData(secondaryObject) {
    return secondaryObject.objectiveData; //Create map sets this up
  }

  static setObjectiveData(secondaryObject, objectiveData) {
    secondaryObject.objectiveData = objectiveData;
  }

  static createSecondaryObject() {
    const secondaryObjectType = CustomMath.executeRandomEvent([
      { name: this.SECONDARY_OBJECT_WORMHOLE, probability: 1 },
      { name: this.SECONDARY_OBJECT_MINOR_FACTION, probability: 12 },
    ]);

    if (secondaryObjectType === this.SECONDARY_OBJECT_WORMHOLE) {
      return { type: this.SECONDARY_OBJECT_WORMHOLE, wormhole: null };
    }
    if (secondaryObjectType === this.SECONDARY_OBJECT_NONE) {
      return {
        type: this.SECONDARY_OBJECT_NONE,
        // Add other properties as needed
      };
    }

    if (secondaryObjectType === this.SECONDARY_OBJECT_MINOR_FACTION) {
      return {
        type: this.SECONDARY_OBJECT_MINOR_FACTION,
        // Add other properties as needed
      };
    }
  }

  static specifyWormholesLetter(systems) {
    //Assign wormhole letters to wormholes
    const wormholeList = [];
    for (let i = 0; i < systems.length; i++) {
      const system = systems[i];
      const spaceObjects = system.objects;
      for (let j = 0; j < spaceObjects.length; j++) {
        if (spaceObjects[j].type === this.SECONDARY_OBJECT_WORMHOLE) {
          wormholeList.push(spaceObjects[j]);
        }
      }
    }

    //If only one wormhole, destroy it
    if (wormholeList.length === 1) {
      wormholeList[0].type = this.SECONDARY_OBJECT_NONE;
      return;
    }

    const wormholeLetters = ["b", "o", "p", "r", "y", "u", "t", "g"];
    const amountPossibleLetters = wormholeLetters.length;
    CustomMath.shuffleArray(wormholeLetters);
    const selectedLetters = [];

    const amountOfWormholes = wormholeList.length;
    for (
      let i = 0;
      i < Math.floor(amountOfWormholes / 2) && i < amountPossibleLetters;
      i++
    ) {
      const letter = wormholeLetters.pop();
      selectedLetters.push(letter);
      selectedLetters.push(letter);
    }
    while (selectedLetters.length < amountOfWormholes) {
      selectedLetters.push(
        selectedLetters[
          CustomMath.generateRandomNumber(0, selectedLetters.length - 1)
        ]
      );
    }

    CustomMath.shuffleArray(selectedLetters);

    for (let i = 0; i < wormholeList.length; i++) {
      wormholeList[i].wormhole = selectedLetters[i];
    }
  }
}

module.exports = SecondaryObject;
