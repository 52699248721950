class CustomMath {
  static generateRandomNumber(min, max) {
    if (min > max) {
      throw new Error("Minimum value must be less than maximum value");
    }

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  static generateRandomReal(min, max) {
    if (min >= max) {
      throw new Error("Minimum value must be less than maximum value");
    }

    return Math.random() * (max - min) + min;
  }

  static executeRandomEvent(events) {
    if (!events || events.length === 0) {
      throw new Error("Event array is empty");
    }

    const totalProbability = events.reduce(
      (sum, event) => sum + event.probability,
      0
    );
    let randomValue = Math.random() * totalProbability;

    for (const event of events) {
      if (randomValue < event.probability) {
        if (event.callback) {
          return event.callback();
        } else {
          return event.name;
        }
      }
      randomValue -= event.probability;
    }

    // Fallback in case of rounding errors
    const lastEvent = events[events.length - 1];
    return lastEvent.callback();
  }

  static shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }

  static roundDec(value) {
    return Math.round(value * 10) / 10;
  }

  static roundDecFloor(value) {
    return Math.floor(value * 10) / 10;
  }

  static roundDecCeil(value) {
    return Math.ceil(value * 10) / 10;
  }

  static roundInt(value) {
    return Math.round(value);
  }

  static checkInteger(value) {
    if (value % 1 > 0) {
      throw new Error("Value must be an integer");
    }
  }

  static getDistance(x1, y1, x2, y2) {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  }

  static sum(array) {
    return array.reduce((a, b) => a + b, 0);
  }
}

module.exports = CustomMath;
