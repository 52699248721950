const AbilityCard = require("../Ability/AbilityCard");
const ActivePassiveAbility = require("../Ability/ActivePassiveAbility");

class FactionAbility {
  //The Rocks
  /*static ROCKS_NATURA_MINERAL_SENSOR = "Natural Mineral Sensor";
  static ROCKS_NATURAL_ROCK_FACTORIES = "Natural Rock Factories";
  static ROCKS_STONE_FORM = "Stone Form";*/

  //Global
  static GLOBAL_TITAN_FORGE_INEPTITUDE = "Titan Forge Ineptitude";
  static GLOBAL_INDUSTRY_FOCUSED = "Industry Focused";
  static GLOBAL_BIG = "Big";
  static GLOBAL_STRONG_SHIPS = "Strong Ships";
  static GLOBAL_RESISTANT_SHIPS = "Resistant Ships";
  static GLOBAL_RESISTANT_WAR_SHIPS = "Resistant War Ships";
  static GLOBAL_TITAN_FORGE_AFFINITY = "Titan Forge Affinity";
  static GLOBAL_CARRIER_EXTRA_CAPACITY = "Carrier Extra Capacity";
  static GLOBAL_RESISTANT = "Resistant";
  static GLOBAL_FRAGILE_WAR_SHIPS = "Fragile War Ships";
  static GLOBAL_FORTIFICATIONS = "Fortifications";
  static GLOBAL_VERY_RICH = "Very Rich";
  static GLOBAL_HYPER_POWERED = "Hyper Powered";

  //Vahl'Ar Skyborne
  static VAHL_AR_SKYBORNE_HYPER_TIDE_DRIVES = "Hyper-Tide Drives";
  static VAHL_AR_SKYBORNE_GRAVITY_SAILS = "Gravity Sails";
  static VAHL_AR_SKYBORNE_VOIDSTREAM_PROPULSION = "Voidstream Propulsion";
  //static VAHL_AR_SKYBORNE_SPATIAL_COMPRESSION_MATRIX = "Spatial Compression Matrix";

  //Concordant Order
  static CONCORDANT_ORDER_VEIL_OF_IMMUNITY = "Veil of Immunity";
  static CONCORDANT_ORDER_SUBVERSION_PROTOCOL = "Subversion Protocol";
  static CONCORDANT_ORDER_RESPECTED = "Respected";

  //Lithorian Collective
  static LITHORIAN_COLLECTIVE_MINERAL_SENSOR = "Natural Mineral Sensor";
  static LITHORIAN_COLLECTIVE_NATURAL_ROCK_FACTORIES = "Natural Rock Factories";
  static LITHORIAN_COLLECTIVE_STONE_FORM = "Stone Form";

  //Ashen Cohort
  static ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM = "Planetary Assault Platform";

  //Dominus Legion
  static DOMINUS_LEGION_PREEMPTIVE_BARRAGE = "Preemptive Barrage";
  static DOMINUS_SIEGE_WARFARE_EXPERTISE = "Siege Warfare Expertise";

  //Astharan Trade Guild
  static ASTHARAN_TRADE_GUILD_MASTER_TRADER = "Master Trader";

  //Etheral Spread
  static ETHERAL_SPREAD_ETHERAL_FORM = "Ethereal Form";
  static ETHERAL_SPREAD_ETHERAL_CONNECTION = "Ethereal Connection";
  static ETHERAL_SPREAD_ETHERAL_HERO_FORM = "Ethereal Hero Form";
  static ETHERAL_SPREAD_ETHERAL_EMBUSK = "Ethereal Embusk";
  static EFFECT_SPREAD_ETHERAL_ETHERAL_HERO_FORM = "Ethereal Hero Form";

  static getAbilityCardDesc(ablityCard) {
    switch (ablityCard.name) {
      //Global
      case this.GLOBAL_RESISTANT:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your infantries have +1 max hp.",
            false
          ),
        ]);
      case this.GLOBAL_RESISTANT_SHIPS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your ships have +1 max hp.",
            false
          ),
        ]);
      case this.GLOBAL_RESISTANT_WAR_SHIPS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your non-fighter ships have +1 max HP.",
            false
          ),
        ]);
      case this.GLOBAL_FRAGILE_WAR_SHIPS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your non-fighter ships have -1 max HP.",
            false
          ),
        ]);
      case this.GLOBAL_STRONG_SHIPS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your ships have +0.1 damage.",
            false
          ),
        ]);
      case this.GLOBAL_INDUSTRY_FOCUSED:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Planet you control can build one additional unit each round.",
            false
          ),
        ]);
      case this.GLOBAL_CARRIER_EXTRA_CAPACITY:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your carriers have +2 capacity.",
            false
          ),
        ]);
      case this.GLOBAL_BIG:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your infantries have +1 max hp, +0.2 damage and +0.3 required capacity.",
            false
          ),
        ]);
      case this.GLOBAL_TITAN_FORGE_INEPTITUDE:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Dreadnoughts you build cost 1 extra minerals. Hell Moons you build cost 2 extra minerals.",
            false
          ),
        ]);
      case this.GLOBAL_TITAN_FORGE_AFFINITY:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Dreadnoughts you build cost 1 less minerals. Hell Moons you build cost 2 less minerals.",
            false
          ),
        ]);
      case this.GLOBAL_FORTIFICATIONS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your structures have +1 morale.",
            false
          ),
        ]);
      case this.GLOBAL_VERY_RICH:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "At the beginning of a round, you gain 2 credits.",
            false
          ),
        ]);
      case this.GLOBAL_HYPER_POWERED:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "At the beginning of a round, you gain 2 energy.",
            false
          ),
        ]);

      //The Etheral Spread
      case this.ETHERAL_SPREAD_ETHERAL_FORM:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your infantries have +2 max hp.",
            false
          ),
          ActivePassiveAbility.createDesc(
            null,
            "The population production of your planets is equal to their ennergy production divided by 2. Technologies impacting population production have no effect.",
            false
          ),
          ActivePassiveAbility.createDesc(
            null,
            "Your infantries cost 0.4 more population.",
            false
          ),
        ]);
      case this.ETHERAL_SPREAD_ETHERAL_CONNECTION:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "At the beginning of a round, If you control planets for a combined total energy production of at least 10, your infantries have +0.2 damage for this round.",
            false
          ),
        ]);
      case this.ETHERAL_SPREAD_ETHERAL_EMBUSK:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "At the beginning of a space combat, each of your infantries on a planet in this system deal their damages to the enemy ships.",
            false
          ),
        ]);

      //The Lithorian Collective
      case this.LITHORIAN_COLLECTIVE_MINERAL_SENSOR:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "At the beginning of a round, gain 0.5 mineral per system you control.",
            false
          ),
        ]);
      case this.LITHORIAN_COLLECTIVE_NATURAL_ROCK_FACTORIES:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Exhaust and pay 3 minerals to place a factory on a planet you control.",
            true
          ),
        ]);
      case this.LITHORIAN_COLLECTIVE_STONE_FORM:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "You infantries cost 1 more mineral and have +1 morale. The amount of population you gain from planets is equal to their mineral production divided by 2. Technologies impacting population production have no effect.",
            false
          ),
        ]);

      //Vahl'Ar Skyborne
      case this.VAHL_AR_SKYBORNE_HYPER_TIDE_DRIVES:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Each hit assigned to your ship from SPACE CANON DEFENSE ability or during the first round of a space combat have a 50% chance of being cancelled.",
            false
          ),
        ]);
      case this.VAHL_AR_SKYBORNE_GRAVITY_SAILS:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Nebulae have no effect on your ships. If your ships start their movement in or pass through an asteroid field, they gain +1 range (not cumulative).",
            false
          ),
        ]);

      case this.VAHL_AR_SKYBORNE_VOIDSTREAM_PROPULSION:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Your destroyers have +1 range.",
            false
          ),
        ]);

      //Concordant Order
      case this.CONCORDANT_ORDER_VEIL_OF_IMMUNITY:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "When another faction attacks you in a space area you control : they lose 1 population. When another faction attacks you on a planet you control : they lose 1 population.",
            false
          ),
        ]);
      case this.CONCORDANT_ORDER_SUBVERSION_PROTOCOL: //OBSOLETE
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "After the combat phase, you take control of a random planet not controlled by any faction in a system adjacent to a planet you control. The relative probability of taking control of a planet is equal to the number of adjacent planets you control to it.",
            false
          ),
        ]);
      case this.CONCORDANT_ORDER_RESPECTED:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "When you interact with a hero, it costs 2 less population, mninimum 2.",
            false
          ),
        ]);

      //Ashen Cohort
      case this.ASHEN_COHORT_PLANETARY_ASSAULT_PLATFORM:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Exhaust to place 2 infantries on a planet adjacent to a planet you control containing one of your factory.",
            true
          ),
        ]);

      //Dominus Legion
      case this.DOMINUS_LEGION_PREEMPTIVE_BARRAGE:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "Before the start of the combat, your non-fighter ships deals their damages, with a +0.3 damage bonus for this roll.",
            false
          ),
        ]);
      case this.DOMINUS_SIEGE_WARFARE_EXPERTISE:
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "When bombarding a planet, if you generate at least one hit, you generate 3 more hits.",
            false
          ),
        ]);

      //Astharan Trade Guild
      case this.ASTHARAN_TRADE_GUILD_MASTER_TRADER: //Obsolete
        return AbilityCard.createDesc(ablityCard, [
          ActivePassiveAbility.createDesc(
            null,
            "You can execute transaction with players who are not your neighbors.",
            false
          ),
        ]);

      /*Your infantries cost 1 minerals.
Your infantries morale is increased by 1.
The amount of population you gain from planets is equal to their mineral production divided by 3.
*/
      default:
        throw new Error(
          "Ability card name not found for faction ability : " + ablityCard.name
        );
    }
  }

  static hasFactionAbility(playerData, abilityName) {
    if (playerData.faction === null) {
      return false;
    }
    const faction = playerData.faction;
    return this.factionHasFactionAbility(faction, abilityName);
  }

  static getFactionAbilityCard(playerData, abilityName) {
    const faction = playerData.faction;
    const abilityCards = faction.abilities;
    for (let i = 0; i < abilityCards.length; i++) {
      if (AbilityCard.getName(abilityCards[i]) === abilityName) {
        return abilityCards[i];
      }
    }
    return null;
  }

  static factionHasFactionAbility(faction, abilityName) {
    const abilityCards = faction.abilities;
    for (let i = 0; i < abilityCards.length; i++) {
      if (AbilityCard.getName(abilityCards[i]) === abilityName) {
        return true;
      }
    }
    return false;
  }

  static readyAllFactionAbilities(playerData) {
    if (playerData.faction === null) {
      return;
    }
    const faction = playerData.faction;
    for (let i = 0; i < faction.abilities.length; i++) {
      AbilityCard.ready(faction.abilities[i]);
    }
  }

  /*        AbilityCard.create(FactionAbility.ROCKS_NATURAL_ROCK_FACTORIES),
        AbilityCard.create(FactionAbility.ROCKS_NATURA_MINERAL_SENSOR),
        AbilityCard.create(FactionAbility.ROCKS_STONE_FORM),
        AbilityCard.create(FactionAbility.GLOBAL_BIG),
        AbilityCard.create(FactionAbility.GLOBAL_INDUSTRY_FOCUSED),
        AbilityCard.create(FactionAbility.GLOBAL_STRONG_SHIPS),
        AbilityCard.create(FactionAbility.GLOBAL_RESISTANT_SHIPS),*/
}

module.exports = FactionAbility;
