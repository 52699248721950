import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import Objectives from "../../Data/GameData/Objectifs/Objectives";
import PublicObjectiveView from "./PublicObjectiveView";
import Color from "../../Common/Config/Colors";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");

const ScoringView = ({}) => {
  const playerData = StaticGameData.getPlayerData();
  const color = PlayerData.getPlayerColor(playerData);
  const allPlayersData = PlayerData.getAllPlayersData(playerData);

  const objectiveNames = Objectives.getPublicObjectives(playerData);

  const DisplayPlayerData = ({ playerDataIteration }) => {
    if (!playerDataIteration.faction) return;

    const scoreData = PlayerData.getScoreData(playerDataIteration);
    const factionColor = Color.getColorFromFaction(
      playerData,
      playerDataIteration.faction.name
    );

    return (
      <BaseButton
        width="100%"
        borderColor={factionColor}
        borderWidth="4px"
        onClick={() => {
          Popup.addLayer({
            name: "FullScreenLogView",
            logBook: ScoreData.getLastLog(scoreData),
            scrollAtEnd: true,
          });
        }}
      >
        <div className="div-fill">
          <span style={{ marginRight: "1em" }}>
            <FactionLogo
              factionName={playerDataIteration.faction.name}
            ></FactionLogo>
          </span>

          <span style={{ marginRight: "2em" }}>
            {playerDataIteration.faction.name}
          </span>
          <span>{ScoreData.getScore(scoreData)}</span>
        </div>
      </BaseButton>
    );
  };

  const DisplayScoringGlobal = () => {
    return (
      <div>
        {allPlayersData.map((playerDataIteration, index) => {
          return (
            <div key={index}>
              {" "}
              <DisplayPlayerData
                playerDataIteration={playerDataIteration}
              ></DisplayPlayerData>{" "}
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayObjectives = () => {
    return (
      <div>
        {objectiveNames.map((objectiveName, index) => {
          return (
            <div key={index}>
              {" "}
              <PublicObjectiveView
                objectiveName={objectiveName}
              ></PublicObjectiveView>{" "}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {" "}
      <DisplayScoringGlobal></DisplayScoringGlobal>{" "}
      <div style={{ marginTop: "1em", marginBottom: "1em" }}>Objectives : </div>
      <DisplayObjectives></DisplayObjectives>
      <BaseButton
        onClick={() => {
          Popup.deActivateBlackPopup();
        }}
      >
        Back
      </BaseButton>
    </div>
  );
};

export default ScoringView;
