import React, { useState } from "react";

import FactionLogo from "./FactionLogo";

const IMAGE_PLANET_VOLCANIC = "/Images/Map/Objects/planet_volcanic.png";
const IMAGE_PLANET_ICE = "/Images/Map/Objects/planet_ice.png";
const IMAGE_PLANET_WATER = "/Images/Map/Objects/planet_water.png";
const IMAGE_PLANET_EARTH = "/Images/Map/Objects/planet_earth.png";
const IMAGE_PLANET_DESERT = "/Images/Map/Objects/planet_desert.png";
const IMAGE_PLANET_GAZ = "/Images/Map/Objects/planet_gaz.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ValueView = ({ value = null }) => {
  if (!value) return <span>no</span>;
  return <span>{value}</span>;
};

export default ValueView;

/*        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",

            transform: "translate(-50%, -50%)",
          }}
        >
          <FactionLogo factionName={planet.faction}></FactionLogo>
        </div>*/
