import React, { useState } from "react";

import BaseButton from "../Utils/BaseButton";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import UnitImageView from "../Object/UnitImageView";
import Style from "../../Common/Config/Style";
import LifeBarView from "./LifeBarView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const Color = require("../../Common/Config/Colors");
const Cost = require("../../Data/GameData/Utils/Cost");

const UnitsStackView = ({ slot, sizeEm = 1, scale = null, mapZoom = null }) => {
  const playerData = StaticGameData.getPlayerData();
  if (slot.length === 0) return null;
  /*const isStackable =
    units[0].type === Unit.UNIT_TYPE_FIGHTER ||
    units[0].type === Unit.UNIT_TYPE_MECH ||
    units[0].type === Unit.UNIT_TYPE_INFANTRY
      ? false
      : true;
  const lineHeight = 1.5;*/
  //const firstTranslateY = -lineHeight * (0.5 * (scale - 1));
  //const secondTranslateY = 1 - scale / 2;
  //console.log("UnitsStackView: secondTranslateY : ", secondTranslateY);
  //console.log("UnitsStackView: slot : ", slot);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {slot.units.map((units, index) => {
        //Sum of hp and hpMax of units
        const hp = units.reduce(
          (acc, unit) => acc + Unit.getHP(playerData, unit),
          0
        );
        const hpMax = units.reduce(
          (acc, unit) => acc + Unit.getMaxHP(playerData, unit),
          0
        );
        /*console.log("units : ", units);
        console.log("hp : ", units);
        console.log("hpMax : ", hpMax);*/

        const left = -index * 15 + "px";
        const top = -index * 15 + "px";
        const adaptedScale =
          units[0].class === Unit.CLASS_STRUCTURE ? scale * 0.75 : scale * 1;
        //const left = 0;
        //const top = 0;
        return (
          <div
            key={index}
            style={{
              zIndex: 2000,
              position: "absolute",
              left: left,
              top: top,

              //transform: "translate(-50%, -50%)",
              //backgroundColor: "RGBA(255,255,0,0.5)",
              //border: "2px solid purple",
              //width: "auto" /* Automatically adjusts width based on content */,
              //height: "auto",
              transform: `translate(-50%, -50%)`,
            }}
          >
            <div className="div-fill" style={{}}>
              <UnitImageView
                unit={units[0]}
                sizeEm={sizeEm}
                scale={adaptedScale}
                showLifeBar={true}
                unitAmount={units.length}
                unitAmountTopPercent={90}
                //lifeBarTopPercent={20}
                lifeBarCompletion={(hp * 100) / hpMax}
                lifeBarTopPercent={50}
                //labelBelow={units[0].type}
                mapZoom={mapZoom}
              ></UnitImageView>
              {false && (
                <div
                  style={{
                    position: "absolute",
                    //top: "50%",
                    left: `50%`,
                    top: "50%",
                    transform: `translate(-50%, -50%)`,
                    fontSize: "0.33em",
                    width: "20px",
                    zIndex: 9999,
                  }}
                >
                  {false && (
                    <LifeBarView
                      hp={hp}
                      maxHp={hpMax}
                      height="1px"
                    ></LifeBarView>
                  )}
                </div>
              )}
            </div>
            {false && units.length === 1 && (
              <div
                style={{
                  position: "absolute",
                  top: `${50 + scale * (50 - 20)}%`,
                  //top: "50%",

                  left: `50%`,
                  transform: `translate(-50%, -0%)`,
                  zIndex: 3000,
                  fontSize: "10px",
                  ...Style.STYLE_MAP_TEXT_BOX,
                }}
              >
                x {units.length}
              </div>
            )}

            {false && units.length > 1 && (
              <div
                style={{
                  position: "absolute",

                  //top: `${50 + scale * (50 - 20)}%`,
                  top: "50%",
                  left: `50%`,
                  transform: `translate(-50%, -0%)`,
                  zIndex: 15000,
                  fontSize: "10px",
                  ...Style.STYLE_MAP_TEXT_BOX,
                }}
              >
                <div
                  className="div-ver-center"
                  style={{ position: "relative" }}
                >
                  {" "}
                  <div style={{ marginBottom: "0px", padding: 0 }}>
                    x {units.length}{" "}
                  </div>{" "}
                  {false && (
                    <div
                      style={{
                        //top: "50%",
                        //fontSize: "0.33em",
                        width: "1.5em",
                        zIndex: 9999,
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <LifeBarView
                        hp={hp}
                        maxHp={hpMax}
                        height="1px"
                      ></LifeBarView>{" "}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default UnitsStackView;
