import React, { useState } from "react";
import LifeBarView from "../Units/LifeBarView.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData.js";
import Color from "../../Common/Config/Colors.js";
import BaseImage from "../Utils/BaseImage.jsx";
import BaseImageMapZoom from "../Utils/BaseImageMapZoom.jsx";

const ImagesUrl = require("../../Data/Other/ImagesUrl.js");
const Unit = require("../../Data/GameData/MapData/Unit.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");

// New DisplayImage component that encapsulates the mask and overlay logic
const DisplayImage = ({
  unitType,
  width,
  height,
  texture,
  imageMaskUrl,
  imageOverUrl,
  size,
  sizeEm,
  scale,
  showLifeBar = false,
  unit = null,
  playerDataOfUnit,
  lifeBarTopPercent,
  lifeBarCompletion,
  unitAmountTopPercent,
  unitAmount,
  mapZoom = null,
}) => {
  const imageStyle = {
    zIndex: 1000,
    width: width,
    height: height,
    /*position: "absolute",
    top: 0,
    left: 0,*/
  };

  /*if (scale) {
    imageStyle.transform = `scale(${scale}) `;
    imageStyle.transformOrigin = "center";
    //imageStyle.backgroundColor = "RGB(100,200,100,0.4";
  } else {
    imageStyle.width = "auto";
    imageStyle.height = size;
  }*/
  const effectiveScale = scale ? scale * Unit.unitGlobalScale : null;

  const containerStyle = { width: width, height: height };

  /*if (effectiveScale) {
    imageStyle.width = Unit.unitImageSizes[unitType].x;
    imageStyle.height = Unit.unitImageSizes[unitType].y;
    imageStyle.transform = `scale(${effectiveScale}) `;
    imageStyle.transformOrigin = "center";
    containerStyle.width = imageStyle.width * effectiveScale + "px";
    containerStyle.height = imageStyle.height * effectiveScale + "px";
  } else {
    if (sizeEm) {
      imageStyle.width =
        (sizeEm * Unit.unitImageSizes[unitType].x) /
          Unit.unitImageSizes[unitType].y +
        "em";
      imageStyle.height = sizeEm + "em";
      containerStyle.width = imageStyle.width + "px";
      containerStyle.height = imageStyle.height + "px";
    }
  }*/

  //imageStyle.width = Unit.unitImageSizes[unitType].x;
  //imageStyle.height = Unit.unitImageSizes[unitType].y;

  return (
    <span
      style={{
        position: "relative",
        display: "inline-block",
        ...containerStyle,
        //border: "1px solid red",

        //backgroundColor: "yellow",
      }}
    >
      {/* Masked texture image */}
      <BaseImageMapZoom
        style={{
          ...imageStyle,
          //width: Unit.unitImageSizes[unitType].x,
          //height: Unit.unitImageSizes[unitType].y,
          maskImage: `url(${imageMaskUrl})`,
          WebkitMaskImage: `url(${imageMaskUrl})`, // For Safari support
          maskSize: "100% 100%",
          WebkitMaskSize: "100% 100%", // For Safari support
        }}
        mapZoom={mapZoom}
        followMapZoom={mapZoom ? true : false}
        onlyOneRes={false}
        src={texture}
        alt="UNIT_TEXTURE_MASK"
      ></BaseImageMapZoom>

      {/* Over image */}
      <BaseImageMapZoom
        src={imageOverUrl}
        alt="UNIT_OVER"
        onlyOneRes={false}
        style={{
          ...imageStyle,
          position: "absolute",
          //width: Unit.unitImageSizes[unitType].x,
          //height: Unit.unitImageSizes[unitType].y,
          zIndex: 6000,
          top: 0,
          left: 0,
        }}
        mapZoom={mapZoom}
        followMapZoom={mapZoom ? true : false}
      ></BaseImageMapZoom>

      <div
        style={{
          /*width: sizeEm + "em",
          position: "absolute",
          top: lifeBarTopPercent + "%",
          left: "50%",
          transform: "translate(-50%, -50%)",*/
          zIndex: 15000,
        }}
      >
        {showLifeBar && unit.class !== Unit.CLASS_STRUCTURE && (
          <div
            style={{
              position: "absolute",
              top: lifeBarTopPercent + "%",
              left: "50%",
              width: "50%",
              transform: "translate(-50%, 0%)",
              zIndex: 14000,
            }}
          >
            <LifeBarView
              hp={Unit.getHP(playerDataOfUnit, unit)}
              maxHp={Unit.getMaxHP(playerDataOfUnit, unit)}
              lifeBarCompletion={lifeBarCompletion}
            ></LifeBarView>
          </div>
        )}
        {unitAmount && unitAmount > 1 && (
          <div
            style={{
              position: "absolute",
              top: unitAmountTopPercent + "%",
              left: "50%",
              backgroundColor: Color.COLOR_MEDIUM_SHADOW,
              borderRadius: "2px",
              whiteSpace: "nowrap",
              display: "inline-block",
              paddingLeft: "0.2em",
              paddingRight: "0.2em",
              transform: "translate(-50%, 0%)",
              fontSize: "75%",
              zIndex: 15000,
            }}
          >
            x {unitAmount}
          </div>
        )}
      </div>
    </span>
  );
};

const UnitImageView = ({
  unit,
  //size = "2em",
  sizeEm = 2,
  scale = null,
  labelBelow = null,
  showLifeBar = true,
  lifeBarTopPercent = 80,
  lifeBarCompletion = null,
  unitType = null,
  unitColor = null,
  unitAmount = null,
  unitAmountTopPercent = 90,
  mapZoom = null,
}) => {
  const playerData = StaticGameData.getPlayerData();
  let playerDataOfUnit = PlayerData.getPlayerDataFromFaction(
    playerData,
    unit.faction
  );
  if (!playerDataOfUnit) {
    playerDataOfUnit = Unit.createFakePlayerData(unit.faction);
  }

  //Determine the size of the unit in pixel or em
  const type = unit ? unit.type : unitType;
  const effectiveScale = scale ? scale * Unit.unitGlobalScale : null;
  const size = {
    width: Unit.unitImageSizes[type].x,
    height: Unit.unitImageSizes[type].y,
  };
  if (effectiveScale) {
    size.width = Unit.unitImageSizes[type].x * effectiveScale + "px";
    size.height = Unit.unitImageSizes[type].y * effectiveScale + "px";
  } else {
    if (sizeEm) {
      size.width =
        (sizeEm * Unit.unitImageSizes[type].x) / Unit.unitImageSizes[type].y +
        "em";
      size.height = sizeEm + "em";
    }
  }

  const color = unit ? unit.color : unitColor;

  const width = Unit.unitImageSizes[type].x;
  const height = Unit.unitImageSizes[type].y;

  const unitAbbreviation = Unit.getSuperShortAbbreviation(type);
  const imageMaskUrl = ImagesUrl.URL_UNIT_BASE + unitAbbreviation + "_mask.png";
  const imageOverUrl = ImagesUrl.URL_UNIT_BASE + unitAbbreviation + "_over.png";
  const texture = ImagesUrl.URL_UNIT_BASE + "texture_" + color + ".png";

  //console.log("DEBUG size : ", size);

  return (
    <span
      style={{
        //position: "relative",
        width: size.width,
        height: size.height,
        //border: "1px solid blue",
      }}
    >
      {/* Use the new DisplayImage component to encapsulate the mask and over image */}
      <DisplayImage
        width={size.width}
        height={size.height}
        unitType={type}
        texture={texture}
        imageMaskUrl={imageMaskUrl}
        imageOverUrl={imageOverUrl}
        sizeEm={sizeEm}
        scale={scale}
        unit={unit}
        showLifeBar={showLifeBar}
        playerDataOfUnit={playerDataOfUnit}
        lifeBarTopPercent={lifeBarTopPercent}
        lifeBarCompletion={lifeBarCompletion}
        unitAmountTopPercent={unitAmountTopPercent}
        unitAmount={unitAmount}
        mapZoom={mapZoom}
      />

      {/* Label and Life bar */}
      {labelBelow && <div>{labelBelow}</div>}
    </span>
  );
};

export default UnitImageView;
