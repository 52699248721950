import React, { useState, useEffect, useRef, Children } from "react";
import "../../CSS/Menu.css";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import Request from "../../Common/Requests/Request";
import StaticGameData from "../../Data/GameData/StaticGameData";
import Color from "../../Common/Config/Colors";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";

import ChatRoom from "../../Data/ChatData/ChatRoom.js";
import FactionLogo from "../Utils/FactionLogo";
import ChatFactionLogo from "./ChatFactionLogo";
import ChatStaticData from "../../Data/ChatData/ChatStaticData";
import ChatData from "../../Data/ChatData/ChatData";
import useChatData from "./UseChatData";
import BaseButton from "../Utils/BaseButton";
import useScrollPosition from "../Utils/Hooks/UseScrollPosition";
import BaseInput from "../Utils/BaseInput.jsx";
import ChatItem from "../../Data/ChatData/ChatItem.js";
import GameUpdate from "../../Data/GameData/Game/GameUpdate.js";

const ChatRequest = require("../../Data/ChatData/ChatRequest");

const ChatRoomInputView = ({ room, styles, scrollContainerRef }) => {
  console.log("ChatRoomInputView RENDER");

  const [newMessage, setNewMessage] = useState("");

  const playerData = StaticGameData.getPlayerData();
  const playerInGameId = playerData.playerInGameId;
  const popup = StaticGameData.popup;

  const inputRef = useRef(null); // Create a ref for the input field

  /*console.log("popup.shouldFocusInput  : ", popup.shouldFocusInput);
  console.log("popup.shouldFocusInput  inputRef.current : ", inputRef.current);*/
  if (popup.shouldFocusInput && inputRef.current) {
    inputRef.current.focus(); // Focus on the input field
    delete popup.shouldFocusInput; // Reset the flag after focusing
  }

  const handleSubmit = () => {
    //e.preventDefault();
    if (newMessage.trim()) {
      // Assuming there's a function to send the new message
      // sendMessage(newMessage);

      //ChatRoom.test();

      //ChatStaticData.addChatItemText(room, newMessage, playerInGameId);

      const chatItem = ChatItem.createMessageText(newMessage, playerInGameId);
      ChatStaticData.roomChatItems[room.id].push(chatItem);
      ChatStaticData.updateChatItems(ChatStaticData.roomChatItems);
      setNewMessage("");
      popup.shouldFocusInput = true;

      //Local change of gameUpdate
      const gameUpdate = playerData.gameUpdate;
      GameUpdate.markRoomAsRed(gameUpdate, room.id);

      //Server change of gameUpdate and get the gameUpdate back
      const sendRequests = async () => {
        await Request.insertChatItem(room.id, chatItem);

        //setRoomChatItems(ChatStaticData.roomChatItems[room.id]);

        //Replace this gameUpdate with the one from the server
        const resultGetGameUpdate = await Request.callPostAPI(
          {
            gameId: StaticGameData.gameState.gameId,
          },
          "gameState-get-game-update",
          () => {},
          false
        );
        const gameUpdateFromGameStateServer = resultGetGameUpdate.gameUpdate;
        playerData.gameUpdate = gameUpdateFromGameStateServer;
      };
      sendRequests();

      Popup.touch();

      /*console.log("updateChatItemsState ", updateChatItemsState);
        if (updateChatItemsState) {
          console.log("updateChatItemsState triggered");
          updateChatItemsState(ChatStaticData.getChatItems(roomData.id));
        }*/

      //Popup.touch();
      //ChatRoom.addChatItemText(roomData, newMessage, playerInGameId);

      // Update the local state for demonstration

      //Update the gameUpdate to mark the room as read, so that the new message does not trigger a notification

      //
      /*console.log("DEBUG local gameUpdate 1 ", gameUpdate);
      await Request.callPostAPI(
        {
          gameUpdateChanges: { roomIdToRead: room.id },
          gameId: StaticGameData.gameState.gameId,
        },
        "gameState-update-light",
        () => {},
        false
      );*/

      //await Request.lightUpdate();

      console.log("DEBUG local gameUpdate 2 ", gameUpdate);

      /*Request.updateChatData(
          updateData,
          ChatRequest.ACTION_ADD_CHAT_ITEM,
          false,
          false
        );*/
    }
  };

  return (
    <form
      //ref={inputRef}
      style={styles.messageInput}
      onSubmit={handleSubmit}
    >
      <BaseInput
        width="100%"
        //ref={inputRef}
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      ></BaseInput>
      <BaseButton onClick={handleSubmit}>Send</BaseButton>
    </form>
  );
};

/*    <input
        className="custom-input"
        //type="text"
        ref={inputRef} // Assign the ref to the input field
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
        //inputMode="text"
        style={styles.input}
      />*/
export default ChatRoomInputView;
