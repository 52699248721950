class GameGlobalFunctions {
  static getPlayerDataFromFaction;

  static getPlayerDataFromInGameId(playerData, playerInGameId) {
    //check if player is the faction
    if (playerData.playerInGameId === playerInGameId) {
      return playerData;
    }

    //Search in other players
    const playerIndex = playerData.otherPlayers.findIndex(
      (player) => player.playerInGameId === playerInGameId
    );
    if (playerIndex === -1) {
      throw new Error("Player not found");
    }
    return playerData.otherPlayers[playerIndex];
  }

  static getPlayerDisplayName(playerData, playerInGameId) {
    const otherPlayerData = this.getPlayerDataFromInGameId(
      playerData,
      playerInGameId
    );
    if (playerData.gameParam.playerNameHidden || !otherPlayerData.username) {
      return "Player " + otherPlayerData.playerInGameId;
    } else {
      return otherPlayerData.username;
    }
  }

  static getAllPlayersData(playerData, onlyRealPlayers = false) {
    return [playerData, ...playerData.otherPlayers];
  }
}

module.exports = GameGlobalFunctions;
