const CustomMath = require("../../../Common/Math/CustomMath.js");

class PlanetBonus {
  static NAME_MINERAL = "Extra Mineral";
  static NAME_CREDIT = "Extra Credit";
  static NAME_RESEARCH = "Extra Research";
  static NAME_BUILD = "Extra Build";
  static NAME_POPULATION = "Extra Population";
  static NAME_VP = "Extra VP";
  static NAME_ENERGY = "Energy";

  static bonus = [
    {
      name: PlanetBonus.NAME_MINERAL,
      effect: "This planet gives one extra mineral.",
    },
    {
      name: PlanetBonus.NAME_CREDIT,
      effect:
        "If you control this planet at the start of a round, gain 1 credit.",
    },
    {
      name: PlanetBonus.NAME_RESEARCH,
      effect: "This planet gives one extra science.",
    },
    {
      name: PlanetBonus.NAME_BUILD,
      effect: "This planet can build 2 extra units each round.",
    },
    {
      name: PlanetBonus.NAME_POPULATION,
      effect: "This planet gives one extra population.",
    },
    {
      name: PlanetBonus.NAME_VP,
      effect: "This planet gives 0.5 extra victory point each round.",
    },
    {
      name: PlanetBonus.NAME_ENERGY,
      effect: "This planet gives one extra energy.",
    },
  ];

  static getBonus(name) {
    for (let i = 0; i < PlanetBonus.bonus.length; i++) {
      if (PlanetBonus.bonus[i].name === name) {
        return PlanetBonus.bonus[i];
      }
    }
    return null;
  }

  static resetBonus(object) {
    object.bonus = [];
  }

  static addSpecificBonus(object, bonusName) {
    object.bonus.push(bonusName);
  }

  static createRandomBonus() {
    let random = CustomMath.generateRandomNumber(
      0,
      PlanetBonus.bonus.length - 1
    );
    return PlanetBonus.bonus[random];
  }

  static getArrayCountBonus = (array, bonusName) => {
    let count = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i] === bonusName) {
        count++;
      }
    }
    return count;
  };
}

module.exports = PlanetBonus;
