import React, { useState } from "react";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData";
import FactionLogo from "../Utils/FactionLogo";
import CostView from "../Utils/CostView";
import ValueView from "../Utils/ValueView";
import UnitDescription from "./UnitDescription";
import UnitLineView from "./UnitLineView";
import UnitImageView from "../Object/UnitImageView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const Popup = require("../../Data/Other/Popup");
const Unit = require("../../Data/GameData/MapData/Unit");
const Color = require("../../Common/Config/Colors");

const UnitDetailedView = ({ unit, factionName }) => {
  const playerData = StaticGameData.getPlayerData();

  let factionNameCalculated = factionName;
  if (!factionNameCalculated) {
    factionNameCalculated = unit.faction;
  }
  /*if (!factionNameCalculated) {
    factionNameCalculated = PlayerData.getFactionNameFromColor(
      playerData,
      unit.color
    );
  }*/
  const factionPlayerData = PlayerData.getPlayerDataFromFaction(
    playerData,
    factionNameCalculated
  );

  const navigationData = {
    buttonsData: [
      {
        text: "Back",
        callback: () => {
          Popup.goBack();
        },
      },
    ],
  };

  const isValueDefined = (value) => {
    if (value) return true;
    return false;
  };

  const abilityDescList = Unit.getAbilitiesDescription(factionPlayerData, unit);

  const DisplayUnit = ({ unit }) => {
    console.log("unit: ", unit);
    return (
      <div className="text-box">
        <div
          style={{
            textAlign: "center",
            fontSize: "1.5em",
            marginBottom: "1em",
          }}
        >
          <FactionLogo factionName={factionNameCalculated}></FactionLogo>
          <span> {Unit.getPrintingName(unit)}</span>
        </div>
        <div style={{ textAlign: "center", fontSize: "6em" }}>
          <UnitImageView unit={unit} showLifeBar={false}></UnitImageView>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "2px",
          }}
        >
          <div>
            {unit.class === Unit.CLASS_STRUCTURE && <span>Hit Point : no</span>}
            {unit.class !== Unit.CLASS_STRUCTURE && (
              <span>
                Hit Point : {Unit.getHP(factionPlayerData, unit)} /{" "}
                {Unit.getMaxHP(factionPlayerData, unit)}
              </span>
            )}
          </div>
          <div>
            <span>Class : {unit.class}</span>
          </div>
          <div>
            <span>
              Mass : <ValueView value={unit.mass}></ValueView>
            </span>
          </div>
          <div>
            <span>
              Range : <ValueView value={unit.range}></ValueView>
            </span>
          </div>

          <div>
            <span>
              Combat :
              {isValueDefined(Unit.getMinDamage(factionPlayerData, unit)) && (
                <span>
                  {Unit.getMinDamage(factionPlayerData, unit)} -{" "}
                  {Unit.getMaxDamage(factionPlayerData, unit)}
                </span>
              )}
              {!isValueDefined(Unit.getMinDamage(factionPlayerData, unit)) && (
                <span>
                  <ValueView
                    value={Unit.getMinDamage(factionPlayerData, unit)}
                  ></ValueView>
                </span>
              )}
            </span>
          </div>
          <div>
            <span>
              Base Morale : <ValueView value={unit.morale}></ValueView>
            </span>
          </div>
          <div>
            <span>
              Capacity :{" "}
              <ValueView
                value={Unit.getCapacity(factionPlayerData, unit)}
              ></ValueView>
            </span>
          </div>
          <div>
            <span>
              Req. Capacity :{" "}
              <ValueView
                value={Unit.getRequiredCapacity(factionPlayerData, unit)}
              ></ValueView>
            </span>
          </div>
          {unit.class === Unit.CLASS_STRUCTURE && <div>Size : {unit.size}</div>}
        </div>

        <div>
          <span>Cost : </span>
          <CostView
            cost={{
              mineral: unit.costMineral,
              science: unit.costScience,
              population: unit.costPopulation,
              influence: unit.costInfluence,
              energy: unit.costEnergy,
              cargo: unit.costCargo,
              credit: unit.costCredit,
            }}
          ></CostView>
        </div>

        {unit.transportRequired && (
          <div>
            <span>
              This unit should be transported by a ship which has capacity.
            </span>
          </div>
        )}

        {abilityDescList.length > 0 && (
          <div>
            {abilityDescList.map((ability, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: Color.COLOR_GREY_1,
                    padding: "10px",
                    borderRadius: "5px",
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: Color.COLOR_GREY_3,
                      padding: "10px",
                    }}
                  >
                    {ability.label}{" "}
                  </div>
                  <div style={{ marginTop: "0.5em" }}>{ability.effect} </div>
                  <div
                    style={{
                      marginTop: "0.5em",
                      backgroundColor: Color.COLOR_GREY_1,
                    }}
                  >
                    <i> {ability.description}</i>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {unit.constructableUnits && (
          <div>
            <span>
              List of units it can build :{" "}
              {unit.constructableUnits.map((item, index) => {
                let unitBuilt = Unit.createUnit(
                  factionPlayerData,
                  factionNameCalculated,
                  item,
                  "",
                  false
                );
                console.log("unitBuilt: ", unitBuilt);
                return (
                  <span key={index} style={{ fontSize: "3em" }}>
                    {" "}
                    <UnitLineView
                      unit={unitBuilt}
                      factionName={factionNameCalculated}
                    ></UnitLineView>{" "}
                  </span>
                );
              })}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <GameGlobalLayout backgroundType="shadow">
      <div
        style={{
          //backgroundColor: Color.COLOR_GREY_1,
          width: "100%",
          height: "100%",
        }}
      >
        {" "}
        <DisplayUnit unit={unit}></DisplayUnit>
        <UnitDescription unit={unit}></UnitDescription>
      </div>

      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default UnitDetailedView;
