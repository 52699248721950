import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";

import IconGridView from "./IconGridView";
import IconView from "../Icon/IconView";
import SpaceObjectImageView from "../Object/SpaceObjectImageView";
import GameState from "../../Data/GameData/Game/GameState";
import Logo from "../Utils/Logo";
import FactionLogo from "../Utils/FactionLogo";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView";
import UnitImageView from "../Object/UnitImageView";
import UnitsStackView from "../Units/UnitsStackView";
import BaseButton from "../Utils/BaseButton";
import ModifyFleetLimitAction from "../../Data/GameData/ActionData/ModifyFleetLimitAction";
import TechButtonView from "../Technology/TechButtonView";
import InteractionFunctions from "../../GameView/UIInteraction/InteractionFunctions";
import InventoryView from "../Transaction/InventoryView.jsx";
import AllAbilitiesView from "../Ability/AllAbilitiesView.jsx";
import BodyView from "../Faction/BodyView.jsx";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData.js";
import ActionModifyFleetLimitView from "../ManageSystem/ActionView/ActionModifyFleetLimitView.jsx";
import ActionPropose from "../ManageSystem/ActionView/ActionPropose.jsx";
import UIMessage from "../../Data/GameData/Connection/UIMessage.js";
import Request from "../../Common/Requests/Request.js";
import ActionBuy from "../ManageSystem/ActionView/ActionBuy.jsx";
import ChatData from "../../Data/ChatData/ChatData.js";
import ChatStaticData from "../../Data/ChatData/ChatStaticData.js";
import GameUpdate from "../../Data/GameData/Game/GameUpdate.js";
import ChatFactionLogo from "../ChatView/ChatFactionLogo.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Popup = require("../../Data/Other/Popup.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData.js");
const ActivationData = require("../../Data/GameData/Activation/ActivationData");
const Phase = require("../../Data/GameData/Game/Phase");
const EndRoundReviewCA = require("../../ClientAction/EndRoundReviewCA");
const Unit = require("../../Data/GameData/MapData/Unit.js");

const IMAGE_PLANET_TEST = "/Images/Map/Objects/planet_test.png";
const IMAGE_SHIP_URL = "/Images/Map/Icon/icon_ship.png";
const IMAGE_GROUND_FORCE_URL = "/Images/Map/Icon/icon_ground_force.png";

const ICON_BACKGROUND_OPACITY = 0.75;
const ICON_FONT_SIZE_PC = 100;

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

const BlinkingExclamation = ({ shouldBlink = true }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (!shouldBlink) {
      setVisible(true); // Ensure it's always visible when blinking is disabled
      return;
    }

    const interval = setInterval(() => {
      setVisible((prev) => !prev); // Toggle visibility
    }, 500); // Adjust the interval (in milliseconds) for blinking speed

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [shouldBlink]);

  return <span style={{ visibility: visible ? "visible" : "hidden" }}>!</span>;
};

const HomeSystemInfoView = ({
  systemSize,
  gapBetweenSystems,
  decallageY,
  minY,
  maxY,
  reverseConnections,
  mapSizeX,
}) => {
  const playerData = StaticGameData.getPlayerData();
  //return;
  const playerdatasToDisplay = [];
  playerdatasToDisplay.push(playerData);
  for (let i = 0; i < playerData.otherPlayers.length; i++) {
    playerdatasToDisplay.push(playerData.otherPlayers[i]);
  }

  const DisplayOnePlayerData = ({ data }) => {
    if (!data.faction || !data.homeSystemCoords) {
      return;
    }

    const borderColor = Color.getColorFromFaction(
      playerData,
      data.faction.name
    );

    if (
      data.homeSystemCoords.y - 1 < minY ||
      data.homeSystemCoords.y - 1 > maxY
    ) {
      return null;
    }

    if (!data.faction.name) return null;
    const coordinates = {
      x:
        (data.homeSystemCoords.x === 1 && !reverseConnections) ||
        (data.homeSystemCoords.x !== 1 && reverseConnections)
          ? -1
          : mapSizeX,
      y: data.homeSystemCoords.y - 1,
    };

    const positionX = coordinates.x * (systemSize + gapBetweenSystems);
    const positionY =
      coordinates.y * (systemSize + gapBetweenSystems) + decallageY;

    //Chat warning esclamation mark
    let roomRed = true;
    let shouldBlink = false;
    let commonRoomRed = true;
    let shouldShowCommonRoom = false;

    if (ChatStaticData.chatData) {
      const gameUpdate = playerData.gameUpdate;
      //console.log("DEBUG 3 gameUpdate", gameUpdate);
      const chatRoomId = ChatData.getRoomFromPlayerInGameId(
        ChatStaticData.chatData,
        data.playerInGameId
      ).id;
      if (!GameUpdate.isRoomRed(gameUpdate, chatRoomId)) {
        roomRed = false;
      }
      if (playerData.faction && data.faction.name === playerData.faction.name) {
        shouldBlink = true;
        shouldShowCommonRoom = true;
        if (!GameUpdate.isRoomRed(gameUpdate, "common")) {
          commonRoomRed = false;
        }
      }
    }

    return (
      <div
        style={{
          position: "absolute",
          top: positionY,
          left: positionX,
          width: systemSize * 1,
          height: systemSize,
          textAlign: "center",
          //backgroundColor: "yellow",
        }}
      >
        {data && data.faction && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "50%",
              transform: "translate(-50%, -100%)",
            }}
          >
            <BodyView color={data.color} size={systemSize / 2}></BodyView>
          </div>
        )}
        <div>
          <FactionLogo factionName={data.faction.name}></FactionLogo>
          <span> {data.faction.name}</span>
        </div>
        <div>
          <span>
            {" "}
            {PlayerData.getPlayerDisplayNameFromSelfPlayerData(data)}
          </span>
        </div>
        <BaseButton
          borderColor={borderColor}
          onClick={() => {
            const scoreData = PlayerData.getScoreData(data);
            Popup.addLayer({
              name: "FullScreenLogView",
              logBook: ScoreData.getLastLog(scoreData),
              scrollAtEnd: true,
            });
          }}
        >
          <div style={{ color: "gold" }}>
            {PlayerData.getVP(data)} VP / {playerData.gameParam.vpLimit}
          </div>
        </BaseButton>
        <BaseButton
          borderColor={borderColor}
          onClick={() => {
            Popup.addLayer({
              name: "MainChatView",
              playerInGameIdActiveScreen: data.playerInGameId,
            });
          }}
        >
          <ChatFactionLogo
            playerInGameId={data.playerInGameId}
            showFactionName={false}
          ></ChatFactionLogo>{" "}
          <Logo logoName="chat icon"></Logo>{" "}
          {!roomRed && (
            <span>
              {" "}
              <BlinkingExclamation shouldBlink={shouldBlink} />
            </span>
          )}
        </BaseButton>
        {shouldShowCommonRoom && (
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.addLayer({
                name: "MainChatView",
                playerInGameIdActiveScreen: "common",
              });
            }}
          >
            <Logo logoName="chat icon"></Logo>
            {!commonRoomRed && (
              <span>
                {" "}
                <BlinkingExclamation shouldBlink={false} />
              </span>
            )}
          </BaseButton>
        )}

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            <Logo logoName="credit"></Logo>
            <span> : {data.credit}</span>
          </div>
          <div>
            <Logo logoName="mineral"></Logo>
            <span> : {data.mineral}</span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span> : {data.science}</span>
          </div>
          <div>
            <Logo logoName="population"></Logo>
            <span> : {data.population}</span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span> : {data.energy}</span>
          </div>
          <div style={{}}>
            <Logo logoName="cargo"></Logo>
            <span> : {data.cargo}</span>
          </div>
          {data.colonists > 0 && (
            <div>
              <span>Colonists : {data.colonists}</span>
            </div>
          )}
        </div>
        {false &&
          playerData.faction &&
          data.faction.name === playerData.faction.name && (
            <div>
              <BaseButton
                type="action"
                onClick={() => {
                  Popup.addAttributes({
                    actionSelectionPopup: true,
                    actionSelectionButtons: [
                      <BaseButton
                        onClick={() => {
                          ModifyFleetLimitAction.prepare(playerData);
                        }}
                      >
                        Modify Fleet Limit <Logo logoName="cargo"></Logo>
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          Popup.addLayer({
                            name: "ManageProductionView",
                          });
                        }}
                      >
                        Production
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          InteractionFunctions.selectASystem(() => {});
                        }}
                      >
                        Test Map Interaction
                      </BaseButton>,
                      <BaseButton
                        onClick={() => {
                          Popup.addLayer({
                            name: "AbilityList",
                            playerData: data,
                          });
                        }}
                      >
                        Abilities
                      </BaseButton>,
                    ],
                  });
                }}
              >
                Action
              </BaseButton>
            </div>
          )}
        <div>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.addLayer({
                name: "FullScreenLogView",
                logBook: PlayerData.getActivityLog(data),
                scrollAtEnd: true,
                rememberScrollKey: "activityLog_" + data.playerInGameId,
              });
            }}
          >
            Logs
          </BaseButton>

          <TechButtonView
            playerInGameId={data.playerInGameId}
            borderColor={borderColor}
          ></TechButtonView>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.activateBlackPopup(
                <InventoryView
                  playerId={PlayerData.getPlayerId(data)}
                ></InventoryView>
              );
            }}
          >
            Items
          </BaseButton>
          <BaseButton
            borderColor={borderColor}
            onClick={() => {
              Popup.addDynamicLayer(
                <AllAbilitiesView playerDataToDisplay={data}></AllAbilitiesView>
              );
            }}
          >
            Abilities
          </BaseButton>
          {playerData.faction &&
            playerData.faction.name === data.faction.name && (
              <BaseButton
                borderColor={borderColor}
                onClick={() => {
                  ActionBuy.prepareUI(playerData);
                }}
              >
                Buy
              </BaseButton>
            )}
          {playerData.faction &&
            data.faction.name === playerData.faction.name && (
              <BaseButton
                borderColor={borderColor}
                onClick={() => {
                  ModifyFleetLimitAction.prepare(playerData);
                  Popup.activateBlackPopup(
                    <ActionModifyFleetLimitView></ActionModifyFleetLimitView>
                  );
                }}
              >
                <Logo logoName="cargo"></Logo>
              </BaseButton>
            )}
          {playerData.faction &&
            data.faction.name !== playerData.faction.name && (
              <BaseButton
                borderColor={borderColor}
                onClick={() => {
                  ActionPropose.prepareUI(
                    playerData,
                    StaticGameData.updateDataOnGameView,
                    data.faction ? data.faction.name : null
                  );
                }}
              >
                <Logo logoName="transaction icon"></Logo>
              </BaseButton>
            )}
        </div>

        {playerData.faction &&
          data.faction.name === playerData.faction.name && (
            <div>
              <div>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({ name: "TransactionListView" });
                  }}
                >
                  <Logo logoName="transaction icon"></Logo> List
                </BaseButton>{" "}
              </div>{" "}
              <div>
                <BaseButton
                  type={playerData.isWaitingEndOfRound ? "greyOut" : "white"}
                  onClick={() => {
                    if (playerData.isWaitingEndOfRound) {
                      UIMessage.displayInfoMessage(
                        "Ready for round " + (playerData.roundNumber + 1) + "",
                        "You already clicked on ready for next round. Please wait for the end of the round."
                      );
                    } else {
                      UIMessage.displayConfirmMessage(
                        "Ready for round " +
                          (playerData.roundNumber + 1) +
                          " ?",
                        "We are round " +
                          playerData.roundNumber +
                          ". Are you ready to go to round " +
                          (playerData.roundNumber + 1) +
                          " ?",
                        () => {
                          if (
                            !ScoreData.getSelectedObjective(playerData) &&
                            !ScoreData.hasScoredObjectiveThisRound(playerData)
                          ) {
                            UIMessage.displayConfirmMessage(
                              "No objectives selected or scored !",
                              "You did not select any objectives or score any objectives this round. You are missing one opportunity to score victory point, as you can only score one objective per round.",
                              () => {
                                Request.nextRound();
                              }
                            );
                          } else {
                            Request.nextRound();
                          }
                        }
                      );
                    }
                  }}
                >
                  ROUND {playerData.roundNumber}
                </BaseButton>{" "}
              </div>
            </div>
          )}
        {data.isWaitingEndOfRound && (
          <div style={{ color: "red" }}>Waiting for new round</div>
        )}
      </div>
    );
  };

  //Otherwise return normal system display
  return (
    <div>
      {playerdatasToDisplay.map((data, index) => (
        <DisplayOnePlayerData key={index} data={data}></DisplayOnePlayerData>
      ))}
    </div>
  );
};

/*<div
            className="div-fill"
            style={{ position: "relative", height: "20%" }}
          >
            <ObjectText></ObjectText>
          </div>*/

export default HomeSystemInfoView;
