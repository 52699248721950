const PlayerData = require("../GameData/PlayerData/PlayerData.js");
const Rules = require("../GameData/Game/Rules.js");
const System = require("../GameData/MapData/System.js");
const Unit = require("../GameData/MapData/Unit.js");
const Map = require("../GameData/MapData/Map.js");
const Fleet = require("../GameData/MapData/Fleet.js");
const LogBook = require("../GameData/Connection/LogBook.js");
const LogMessage = require("../GameData/Connection/LogMessage.js");
const LogAttachment = require("../GameData/Connection/LogAttachment.js");

class ChatItem {
  static TYPE_TEXT = "text";

  static createMessageText(text, playerInGameId) {
    return {
      type: this.TYPE_TEXT,
      content: text,
      playerInGameId: playerInGameId,
    };
  }
}

module.exports = ChatItem;
