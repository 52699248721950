import React, { useState, useEffect } from "react";
import { LoginMainScreen } from "../login/login";
import { MainMenu } from "../MainMenu/MainMenu";
import GameView from "../GameView/GameView";
import TestView from "./TestView";
import StaticGameData from "../Data/GameData/StaticGameData";
import GameGlobalFunctions from "../Data/Other/GameGlobalFunctions";
import PlayerData from "../Data/GameData/PlayerData/PlayerData";
import InitializeGlobal from "../Data/Other/InitializeGlobal";
import DynamicView from "../GameView/DynamicView";
import Popup from "../Data/Other/Popup";
import WelcomeScreen from "./WelcomeScreen";
import MainBlackPopup from "./Utils/MainBlackPopup";
import LoadingView from "../GameView/LoadingView";
import UIMessage from "../Data/GameData/Connection/UIMessage";
import MessageView from "../GameView/MessageView";
import LineSpace from "../GameView/Utils/Layout/LineSpace";

const MainComponent = () => {
  const screenSize = [window.innerWidth, window.innerHeight];
  const testView = false;

  //Main Popup
  const [popup, setPopup] = useState(Popup.createPopup({}));
  const [inGame, setInGame] = useState(false);
  useEffect(() => {
    // This block will run only once on component mount
    // Setting the server mode to false, so that the code knows we are at the client side
    StaticGameData.serverMode = false;
    StaticGameData.popup = popup;
    StaticGameData.setPopupReference = setPopup;
    StaticGameData.setInGame = setInGame;
  }, []);

  // State to keep track of whether the user is logged in or not
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [routeMain, setRouteMain] = useState({
    activeScreen: "MainMenu",
  });

  const setRouteMainHandler = (routeMain) => {
    //console.log("Setting routeMain to:", routeMain);
    setRouteMain(routeMain);
  };

  useEffect(() => {
    // Function to check the token's validity on the server
    const checkTokenValidity = async () => {
      try {
        // Make an API request to check the token's validity
        const response = await fetch("/api/check-token-validity", {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          // Token is valid, user is logged in
          console.log("Token is valid");
          setIsUserLogged(true);
          StaticGameData.username = result.username;
          Popup.addDynamicLayer(<WelcomeScreen></WelcomeScreen>);
        } else {
          // Token is invalid or not present, user is not logged in
          console.log("Token is invalid");
          setIsUserLogged(false);
          Popup.addDynamicLayer(
            <LoginMainScreen
              setIsUserLogged={setIsUserLogged}
            ></LoginMainScreen>
          );
        }
      } catch (error) {
        console.error("Error occurred during API request:", error);
      }
    };

    // Call the function to check token validity on component mount
    checkTokenValidity();
  }, []);

  //Initialize global functions
  useEffect(() => {
    InitializeGlobal.ini();
  }, []);

  // Function to handle user login
  const handleLogin = () => {
    // Implement your login logic here
    // For example, you could call an authentication API endpoint
    // and upon successful login, set isUserLogged to true
    setIsUserLogged(true);
  };

  // Function to handle user logout
  const handleLogout = () => {
    // Implement logout logic here
    // For example, clear authentication tokens or user data
    setIsUserLogged(false);
  };

  if (testView && isUserLogged) {
    return (
      <div style={{ width: "100vw", height: "100%" }}>
        <TestView></TestView>
      </div>
    );
  }

  if (inGame) {
    return <GameView game={Popup.game} screenSize={screenSize}></GameView>;
  }

  return (
    <div
      style={{
        fontFamily: "CustomFont",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "auto",
        userSelect: "none",
        //outline: "2px solid rgba(0,0, 255, 0.7)",
        //outlineOffset: "-4px",
      }}
    >
      {popup && popup.name && popup.name === "DynamicView" && (
        <DynamicView
          displayComponent={popup.displayComponent}
          popup={Popup.popup}
        ></DynamicView>
      )}
      {StaticGameData.popup && popup && Popup.isBlackPopupActive() && (
        <MainBlackPopup popup={popup}></MainBlackPopup>
      )}
      {UIMessage.isThereMessageInStaticGameData() && (
        <MessageView></MessageView>
      )}
      {Popup.IS_CLIENT_WAITING_SERVER && <LoadingView></LoadingView>}
      {false && <div style={{ marginTop: "3em" }}></div>}
    </div>
  );

  return (
    <div>
      {isUserLogged ? (
        <div>
          {routeMain.activeScreen === "MainMenu" && (
            <MainMenu setRouteMainHandler={setRouteMainHandler} />
          )}
          {routeMain.activeScreen === "GameView" && (
            <GameView
              setRouteMainHandler={setRouteMainHandler}
              game={routeMain.game}
              screenSize={screenSize}
            />
          )}
        </div>
      ) : (
        <div>
          <LoginMainScreen setIsUserLogged={setIsUserLogged}></LoginMainScreen>
        </div>
      )}
    </div>
  );
};

export default MainComponent;
