const PlayerData = require("../PlayerData/PlayerData.js");
const Rules = require("../Game/Rules.js");
const Map = require("../MapData/Map.js");
const System = require("../MapData/System.js");
const Fleet = require("../MapData/Fleet.js");
const UIMessage = require("../Connection/UIMessage.js");
const Unit = require("../MapData/Unit.js");
const Popup = require("../../Other/Popup.js");
const Planet = require("../MapData/Planet.js");
const LogMessage = require("../Connection/LogMessage.js");
const SystemAction = require("../ActionCommon/SystemAction.js");
const ValidAction = require("../ActionCommon/WarningAction.js");
const WarningAction = require("../ActionCommon/WarningAction.js");
const Cost = require("../Utils/Cost.js");
const LogBook = require("../Connection/LogBook.js");
const LogAttachment = require("../Connection/LogAttachment.js");
const CheckAction = require("../ActionCommon/CheckAction.js");
const Phase = require("../Game/Phase.js");
const StaticGameData = require("../StaticGameData.js");
const CustomMath = require("../../../Common/Math/CustomMath.js");
const Request = require("../../../Common/Requests/Request.js");
const ActionCommonData = require("./ActionCommonData.js");
const TechTree = require("../Technology/TechTree.js");
const Tech = require("../Technology/Tech.js");
const TechList = require("../Technology/TechList.js");
const ResolveTechAbility = require("../Ability/ResolveTechAbility.js");

class ResearchActionData {
  static prepare(playerData, techName) {
    const data = {
      techName: techName,
    };

    ActionCommonData.prepare(
      playerData,
      Phase.PHASE_ACTION,
      Phase.STEP_ACTION_RESEARCH_TECH,
      data,
      false
    );
  }

  //Resolve
  static clientResolve(playerData) {
    const sendToServer = () => {
      ActionCommonData.resolveClient(playerData);
    };

    sendToServer();
  }

  static serverResolve(playerData, resolveData) {
    ActionCommonData.resolveServerIni(playerData, resolveData);

    const data = ActionCommonData.getData(playerData);
    const tech = TechTree.getTechFromName(playerData.techTree, data.techName);

    ResearchActionData.researchTech(playerData, tech, 0);
    PlayerData.generateLogActivity(playerData, "Researched " + tech.name + ".");
  }

  static researchTech(
    playerData,
    tech,
    amountMissingPreRequisitesAllowed,
    haveToPayCost = true
  ) {
    if (tech.owned) {
      throw new Error("Tech already owned. You cannot research it again.");
    }

    const missingPrerequisites = ResearchActionData.checkMissingPrerequisites(
      playerData,
      tech
    );

    const strictlyMissingPrerequisites =
      missingPrerequisites.length - amountMissingPreRequisitesAllowed;

    if (strictlyMissingPrerequisites > 0) {
      let errorLog =
        "You are missing " +
        strictlyMissingPrerequisites +
        " prerequisites to research this tech.";
      throw new Error(errorLog);
    }

    if (haveToPayCost) {
      PlayerData.spendCost(playerData, Cost.createCost(tech.cost));
    }

    tech.owned = true;

    //Effect after research
    ResolveTechAbility.applyUpgradeOnAllUnits(playerData, tech.name);
  }

  static checkMissingPrerequisites(playerData, tech) {
    const techPrerequisites = [...tech.requirements];
    const playerOwnedReq = TechTree.getOwnedRequisites(playerData.techTree);
    for (let i = 0; i < playerOwnedReq.length; i++) {
      const req = playerOwnedReq[i];
      //remove one single requisite matching in techPrerequisite
      const index = techPrerequisites.indexOf(req);
      if (index > -1) {
        techPrerequisites.splice(index, 1);
      }
    }

    return techPrerequisites;
  }
}

module.exports = ResearchActionData;
