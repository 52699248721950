import React, { useState, useEffect } from "react";
import MapView from "../GameView/MapView";
import DraftView from "../GameView/DraftView";
import TaskBarView from "./TaskBarView";
import ErrorView from "./ErrorView";
import BuildUnitsWFView from "../Workflow/BuildUnitsWF/BuildUnitsWFView";
import Banner from "./Banner";
import HelpPopup from "../Help/HelpPopup";
import ManageSystemView from "./ManageSystem/ManageSystemView";
import ManageObjectView from "./ManageSystem/ManageObjectView";
import NavigationView from "./NavigationView";
import MessageView from "./MessageView";
import ManageActivationView from "./ManageSystem/ManageActivationView";
import ActivationData from "../Data/GameData/Activation/ActivationData";
import FullScreenLogView from "./LogViews/FullScreenLogView";
import ActivationCA from "../ClientAction/ActivationCA";
import GameFactionsView from "./GameFactionsView";
import GameMenuView from "./GameMenuView";
import ImagesUrl from "../Data/Other/ImagesUrl";
import FleetDetailedView from "./ManageSystem/FleetDetailedView";
import UnitDetailedView from "./Units/UnitDetailedView";
import LoadingView from "./LoadingView";
import MandatoryActionGlobalView from "./MandatoryActionView.jsx/MandatoryActionGlobalView";
import ManageLogView from "./ManageSystem/ManageLogView";
import MapInteractionPopup from "./MapInteraction/MapInteractionPopup";
import ActionGlobalView from "./ManageSystem/ActionView/ActionGlobalView";
import ActionSelectionPopup from "./ManageSystem/ActionView/ActionSelectionPopup";
import ManageProductionView from "./ManageViews/ManageProductionView";
import MainChatView from "./ChatView/MainChatView";
import TechTreeView from "./Technology/TechTreeView";
import TechView from "./Technology/TechView";
import CombatView from "./Combat/CombatView";
import DynamicView from "./DynamicView";
import AbilityList from "./Ability/AbilityList";
import BlackPopup from "./Utils/Popups/BlackPopup";
import TransactionListView from "./Transaction/TransactionListView";
import GameUpdate from "../Data/GameData/Game/GameUpdate";
import { RenderProviderMapZoom } from "./Utils/Hooks/RenderContextMapZoom"; // Updated import
import ChatData from "../Data/ChatData/ChatData";
import ChatRoom from "../Data/ChatData/ChatRoom";
import LogBook from "../Data/GameData/Connection/LogBook";
import { Tutorial } from "../Data/GameData/Tutorial/Tutorial";

const Request = require("../Common/Requests/Request");

const StaticGameData = require("../Data/GameData/StaticGameData");
const Popup = require("../Data/Other/Popup");
const OngoingAction = require("../Data/GameData/Game/OngoingAction");
const CARouter = require("../ClientAction/CARouter");
const PlayerData = require("../Data/GameData/PlayerData/PlayerData");
const Map = require("../Data/GameData/MapData/Map");
const Phase = require("../Data/GameData/Game/Phase");
const Color = require("../Common/Config/Colors");
const UIMessage = require("../Data/GameData/Connection/UIMessage");
const ChatStaticData = require("../Data/ChatData/ChatStaticData");

const IMAGE_SPACE_BACKGROUND = "/Images/Map/background_space.png";

const GameView = ({ game, screenSize }) => {
  const [gameState, setGameState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(Popup.createPopup({ name: "MapView" }));

  const [transactionData, setTransactionData] = useState(null);
  const [chatData, setChatData] = useState(null);
  const [chatItems, setChatItems] = useState(null);

  const updateTransactionData = (transactionData) => {
    setTransactionData(transactionData);
    StaticGameData.transactionData = transactionData;
  };

  const updateChatData = (chatData) => {
    setChatData(chatData);
    ChatStaticData.chatData = chatData;
  };

  const updateChatItems = (chatItems) => {
    setChatItems(chatItems);
    ChatStaticData.roomChatItems = chatItems;
  };

  useEffect(() => {
    // This block will run only once on component mount
    // Setting the server mode to false, so that the code knows we are at the client side
    StaticGameData.serverMode = false;
    StaticGameData.popup = popup;
    StaticGameData.setPopupReference = setPopup;
    StaticGameData.setGameStateReference = setGameState;
    StaticGameData.gameState = { gameId: game.gameId };
    StaticGameData.setUpdatesTransactions(updateTransactionData);
    ChatStaticData.updateChatData = updateChatData;
    ChatStaticData.updateChatItems = updateChatItems;

    const fetchGameState = async () => {
      //Initial fetch of game
      //This sets the gameState and also call GameView.setGameState via StaticGameData
      if (!game) {
      }

      await Request.getGameState();
      setLoading(false);

      //Load chat data
      const chatDataTemp = await Request.getChatData();
      const rooms = ChatData.getRoomIdList(chatDataTemp);

      for (let i = 0; i < rooms.length; i++) {
        const roomId = rooms[i];
        const room = ChatData.getRoomFromId(chatDataTemp, roomId);

        ChatStaticData.roomChatItems[roomId] = await Request.getChatItems(
          roomId,
          room.lastItemId - 299,
          null
        );
      }
      updateChatItems(ChatStaticData.roomChatItems);

      updateChatData(chatDataTemp);
    };
    fetchGameState();
  }, []);

  //Tutorial initialization
  useEffect(() => {
    if (gameState && gameState.isTutorial) {
      gameState.playerData.tutorial = {
        waitingOnStep: null,
        waitingOnScreenName: null,
        stepName: "introduction",
      };
      if (gameState.playerData.tutorial) {
        Tutorial.routeCurrentStep(gameState.playerData);
      }
    }
  }, [gameState]);

  /*const updateData = async () => {
    const data = await Request.getUpdateRequiredInfo();
    if (data) {
      setTransactionData(data.transactionData);
      StaticGameData.setTransactionData(data.transactionData);
    }
  };

  StaticGameData.setUpdatesTransactions(updateData);
  StaticGameData.updateDataOnGameView = updateData;

  useEffect(() => {
    //Use effect for updating cross players info like transaction

    const interval = setInterval(() => {
      updateData();
    }, 15000); // 30000 milliseconds = 30 seconds

    updateData();

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures this runs only once, like componentDidMount
*/
  //Initialize the client action : initialize the screen, and everything based on the phase and step of the gameState

  /*if (gameState) {
    console.log(
      "MessageView: messages in gameview",
      gameState.playerData.messageToDisplay
    );
  }*/

  useEffect(() => {
    // Function that triggers every 15 seconds
    const checkForGameUpdate = (gameState) => {
      try {
        if (StaticGameData.gameState) {
          Request.callPostAPI(
            { gameId: StaticGameData.gameState.gameId },
            "get-gameUpdate",
            (gameUpdate) => {
              //GameState Update if needed
              if (
                GameUpdate.doesNeedGameUpdate(
                  StaticGameData.gameState.playerData.gameUpdate,
                  gameUpdate
                )
              ) {
                //Check if we do a light upgrade or a full upgrade, based on round number.
                Request.routeLightHeavyUpdate(gameUpdate.currentRound);
              } else {
                console.log("GameUpdate : ", " No need to update the game");
              }

              //Chat Items update if needed
              const roomUpdateList = gameUpdate.roomUpdateList;
              for (let i = 0; i < roomUpdateList.length; i++) {
                const roomUpdateServer = roomUpdateList[i];
                const roomUpdateLocal = GameUpdate.getRoomUpdate(
                  StaticGameData.gameState.playerData.gameUpdate,
                  roomUpdateServer.roomId
                );
                if (
                  roomUpdateServer &&
                  roomUpdateLocal &&
                  GameUpdate.doesNeedRoomUpdate(
                    StaticGameData.gameState.playerData.gameUpdate,
                    roomUpdateLocal.roomId,
                    gameUpdate
                  )
                ) {
                  /*console.log(
                    "GameUpdate : ",
                    " Need to update the room " + roomUpdateLocal.roomId
                  );*/
                  const startIndex = Math.max(
                    roomUpdateLocal.lastChatItemLoaded,
                    roomUpdateServer.lastChatItemLoaded - 299
                  );
                  Request.getChatItems(
                    roomUpdateLocal.roomId,
                    startIndex,
                    null
                  );
                } else {
                  /* console.log(
                    "GameUpdate : ",
                    " No need to update the room " +
                      roomUpdateLocal.roomId +
                      " : " +
                      roomUpdateServer.lastChatItemLoaded +
                      " : " +
                      roomUpdateLocal.lastChatItemLoaded
                  );*/
                }
              }
            },
            false
          );
        }

        // Handle gameUpdate if needed
      } catch (error) {
        console.error("Error fetching game update:", error);
      }
    };

    const interval = setInterval(() => {
      checkForGameUpdate();
    }, 7500); // 15000 milliseconds = 15 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!gameState) {
      return;
    }
    console.log("Route CA...");
    CARouter.routeCA(gameState);

    /*OngoingAction.updatePopupForOngoingAction(
      StaticGameData.getPlayerData().ongoingAction
    );*/
    /*if (gameState.playerData.phase === Phase.ACTIVATION) {
      ActivationCA.initialize_PHASE_ACTIVATION(gameState);
    }*/
  }, [gameState]);

  const handleSaveState = async () => {
    await Request.updateGameState({
      gameId: game.gameId,
      reqType: "Admin",
      reqSubType: "SaveTestState",
    });
  };
  const handleNextRound = async () => {
    await Request.triggerNextRound();
  };
  const handleLoadState = async () => {
    await Request.updateGameState({
      gameId: game.gameId,
      reqType: "Admin",
      reqSubType: "LoadTestState",
    });
    await Request.getGameState();
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!gameState) {
    return <div>Error loading game state.</div>;
  }

  // Render the ongoing game based on the gameState
  const containerStyle = {
    //backgroundImage: `url(${IMAGE_SPACE_BACKGROUND})`,
    //backgroundSize: "cover",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",

    fontWeight: "bold",
    color: "white",
    lineHeight: "1.5",
    fontSize: "1rem",
    //backgroundColor: Color.COLOR_GREY_1,
    backgroundImage: `url(${ImagesUrl.URL_MAP_BACKGROUND})`,

    backgroundSize: "cover", // Adjusts the background image size to cover the entire element
    backgroundPosition: "center", // Centers the background image
    backgroundRepeat: "no-repeat", // Prevents the background image from repeatin
    overflow: "auto",
    userSelect: "none",
  };

  /*const handleMouseDown = (e) => {
    e.preventDefault();
  };*/

  window.scrollTo(0, 1);

  console.log("GameView: popup", popup);
  console.log("GameView: gameState", gameState);
  console.log("GameView: game", Popup.game);

  return (
    <div
      style={containerStyle}
      //onMouseDown={handleMouseDown}
    >
      {popup &&
        popup.name === "ManageActivationView" &&
        ActivationData.isASystemActivated(gameState.playerData) && (
          <ManageActivationView
            system={ActivationData.getActiveSystem(
              StaticGameData.getPlayerData()
            )}
            activationData={StaticGameData.getPlayerData().activationData}
          />
        )}
      {popup && popup.name === "MapView" && (
        <RenderProviderMapZoom>
          <MapView
            popup={popup}
            playerData={StaticGameData.getPlayerData()}
            mapLightData={Popup.mapLightData}
          />
        </RenderProviderMapZoom>
      )}
      {popup && popup.name === "ActionGlobalView" && (
        <ActionGlobalView></ActionGlobalView>
      )}
      {popup && popup.name === "MandatoryActionGlobalView" && (
        <MandatoryActionGlobalView />
      )}
      {popup && popup.name === "ManageSystemView" && (
        <ManageSystemView
          system={Map.getSystemFromSystem(
            StaticGameData.getPlayerData().map,
            popup.system
          )}
        />
      )}
      {popup && popup.name === "ManageObjectView" && (
        <ManageObjectView spaceObject={popup.spaceObject} />
      )}
      {popup && popup.name === "ManageLogView" && (
        <ManageLogView
          combatLog={popup.combatLog}
          activityLog={popup.activityLog}
          type={popup.type}
        />
      )}
      {popup && popup.name === "CombatView" && (
        <CombatView combatKey={popup.combatKey} />
      )}

      {popup && popup.name === "FullScreenLogView" && (
        <FullScreenLogView
          logBook={popup.logBook}
          scrollAtEnd={popup.scrollAtEnd}
          rememberScrollKey={popup.rememberScrollKey}
        />
      )}
      {popup && popup.name === "DraftView" && (
        <DraftView gameState={StaticGameData.getPlayerData} />
      )}
      {popup && popup.name === "BuildUnits" && (
        <BuildUnitsWFView gameState={StaticGameData.getPlayerData} />
      )}
      {popup && popup.name === "HelpPopup" && (
        <HelpPopup helpReference={popup.helpReference} />
      )}
      {popup && popup.name === "GameFactionsView" && <GameFactionsView />}
      {popup && popup.name === "GameMenuView" && <GameMenuView />}
      {popup && popup.name === "MainChatView" && (
        <MainChatView
          playerData={gameState.playerData}
          chatData={chatData}
          chatItems={chatItems}
        />
      )}
      {popup && popup.name === "FleetDetailedView" && (
        <FleetDetailedView fleet={popup.fleet}></FleetDetailedView>
      )}
      {popup && popup.name === "ManageProductionView" && (
        <ManageProductionView />
      )}
      {popup && popup.name === "UnitDetailedView" && (
        <UnitDetailedView
          unit={popup.unit}
          factionName={popup.factionName}
        ></UnitDetailedView>
      )}
      {popup && popup.name === "TechTreeView" && (
        <TechTreeView playerInGameId={popup.playerInGameId}></TechTreeView>
      )}
      {popup && popup.name === "TechView" && (
        <TechView tech={popup.tech}></TechView>
      )}
      {popup && popup.name === "AbilityList" && (
        <AbilityList playerData={popup.playerData}></AbilityList>
      )}
      {popup && popup.name === "TransactionListView" && (
        <TransactionListView
          transactionData={transactionData}
          //updateDataOnGameView={updateData}
        ></TransactionListView>
      )}
      {popup && popup.name === "DynamicView" && (
        <DynamicView
          displayComponent={popup.displayComponent}
          popup={popup}
        ></DynamicView>
      )}
      {popup && Popup.isBlackPopupActive() && (
        <BlackPopup popup={popup}></BlackPopup>
      )}

      {UIMessage.isThereAMessageToDisplay(gameState.playerData) && (
        <MessageView playerData={gameState.playerData}></MessageView>
      )}

      {popup.actionSelectionPopup && (
        <ActionSelectionPopup popup={popup}></ActionSelectionPopup>
      )}
      {Popup.IS_CLIENT_WAITING_SERVER && <LoadingView></LoadingView>}
    </div>
  );
};

export default GameView;

/*          <MapView
            visible={popup && popup.name === "MapView"}
            playerData={StaticGameData.getPlayerData()}
          />*/

/*{gameViewRoute.activeScreen === "TileCommandView" && (
        <TileCommandView
          gameState={gameState}
          gameViewRoute={gameViewRoute}
          setGameViewRoute={setGameViewRoute}
        />
      )}*/

/*<div
  className="div-ver-center"
  style={{ position: "absolute", top: 0, left: 0 }}
>
  <div className="div-fill" style={{ height: "5vh" }}>
    <Banner style={{ width: "100vw", height: "5vh" }}></Banner>
  </div>
  <div className="div-fill" style={{ height: "95vh", position: "relative" }}>
    {popup && popup.name === "MapView" && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 3000,
        }}
      >
        <button onClick={handleBackButtonClick}>Back</button>
        <button onClick={handleSaveState}>Save State</button>
        <button onClick={handleLoadState}>Load State</button>
        <button onClick={handleNextRound}>Next Round</button>
      </div>
    )}
  </div>
</div>;
{
  popup.errorMessage && <ErrorView></ErrorView>;
}*/
