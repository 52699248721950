const Color = require("../../../Common/Config/Colors");

class Transaction {
  static STATE_PROPOSED = "Proposed";
  static STATE_ACCEPTED = "Accepted";
  static STATE_REJECTED = "Rejected";

  static create(proposingFaction) {
    return {
      proposingFaction: proposingFaction,
      receivingFaction: null,
      itemsOut: [],
      itemsIn: [],
      creditBalance: 0, //positive if receiving faction is receiving
      state: Transaction.STATE_PROPOSED,
    };
  }

  static addItemIn(transaction, item) {
    const index = transaction.itemsIn.indexOf(item);
    if (index > -1) {
      return;
    }
    transaction.itemsIn.push(item);
  }

  static removeItemIn(transaction, item) {
    for (let i = 0; i < transaction.itemsIn.length; i++) {
      if (transaction.itemsIn[i].id === item.id) {
        transaction.itemsIn.splice(i, 1);
        return;
      }
    }
  }

  static addItemOut(transaction, item) {
    const index = transaction.itemsOut.indexOf(item);
    if (index > -1) {
      return;
    }
    transaction.itemsOut.push(item);
  }

  static removeItemOut(transaction, item) {
    for (let i = 0; i < transaction.itemsOut.length; i++) {
      if (transaction.itemsOut[i].id === item.id) {
        transaction.itemsOut.splice(i, 1);
        return;
      }
    }
  }

  static modifyCreditBalance(transaction, amount) {
    transaction.creditBalance += amount;
  }

  static setReceivingFaction(transaction, receivingFaction) {
    if (transaction.receivingFaction !== receivingFaction) {
      transaction.itemsIn = [];
    }
    transaction.receivingFaction = receivingFaction;
  }

  static getReceivingFaction(transaction) {
    return transaction.receivingFaction;
  }

  static getProposingFaction(transaction) {
    return transaction.proposingFaction;
  }

  static getItemsIn(transaction) {
    return transaction.itemsIn;
  }

  static getItemsOut(transaction) {
    return transaction.itemsOut;
  }

  static getCreditBalance(transaction) {
    return transaction.creditBalance;
  }

  static isAutomaticallyAccepted(transaction) {
    return transaction.itemsIn.length === 0 && transaction.creditBalance >= 0;
  }
}

module.exports = Transaction;
