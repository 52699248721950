import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ChatFactionLogo from "../ChatView/ChatFactionLogo";
import Logo from "../Utils/Logo";
import LogBook from "../../Data/GameData/Connection/LogBook";
import LogBookView from "../Utils/LogMessage/LogBookView";
import Item from "../../Data/GameData/Transactions/Item";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const TransactionData = require("../../Data/GameData/Transactions/TransactionData");
const Transaction = require("../../Data/GameData/Transactions/Transaction");

const TransactionItemsView = ({ transaction, itemIn = false }) => {
  const itemsToDisplay = itemIn ? transaction.itemsIn : transaction.itemsOut;

  const generateItemLogbook = () => {
    let text = "";
    const textData = [];

    //Credits
    if (transaction.creditBalance > 0 && itemIn === false) {
      text = text + "$logo$ " + transaction.creditBalance + ", ";
      textData.push("credit");
    }
    if (transaction.creditBalance < 0 && itemIn === true) {
      text = text + "$logo$ " + -transaction.creditBalance + ", ";
      textData.push("credit");
    }

    let items = [];
    if (itemIn === true) {
      items = transaction.itemsIn;
    }
    if (itemIn === false) {
      items = transaction.itemsOut;
    }
    const artefactList = Item.getArtifactsFromItems(items);
    const relicList = Item.getRelicsFromItems(items);

    for (let i = 0; i < artefactList.length; i++) {
      const desc = Item.getDescription(artefactList[i]);
      text = text + desc.text + ", ";
    }

    for (let i = 0; i < relicList.length; i++) {
      const desc = Item.getDescription(relicList[i]);
      text = text + "$faction$" + desc.text + ", ";
      textData.push(desc.faction);
    }
    //Items
    //Todo

    //remove last comma and space if there is one
    if (text.slice(-2) === ", ") {
      text = text.slice(0, -2);
    }

    return LogBook.generateLogBook(text, textData);
  };

  return (
    <div
      className="text-box"
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: "1em",
      }}
    >
      {" "}
      <LogBookView logBook={generateItemLogbook()}></LogBookView>
    </div>
  );
};

export default TransactionItemsView;
