import React, { useState, useEffect } from "react";
import FactionLogo from "../Utils/FactionLogo";
import ScoreData from "../../Data/GameData/Objectifs/ScoreData";
import FactionSpan from "../Utils/FactionSpan";
import BaseButton from "../Utils/BaseButton";
import Popup from "../../Data/Other/Popup";
import GameGlobalLayout from "../GameGlobalLayout";
import NavigationView from "../NavigationView";
import TransactionView from "./TransactionView";
import ChatFactionLogo from "../ChatView/ChatFactionLogo";
import Logo from "../Utils/Logo";
import TransactionItemsView from "./TransactionItemsView";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData";
import Item from "../../Data/GameData/Transactions/Item";
import SelectionListView from "../Utils/Layout/SelectionListView";
import ItemView from "./ItemView";
import UIMessage from "../../Data/GameData/Connection/UIMessage";
import ItemDetailedView from "./ItemDetailedView";

const StaticGameData = require("../../Data/GameData/StaticGameData");
const PlayerData = require("../../Data/GameData/PlayerData/PlayerData");
const TransactionData = require("../../Data/GameData/Transactions/TransactionData");
const Transaction = require("../../Data/GameData/Transactions/Transaction");
const ProposeTransactionActionData = require("../../Data/GameData/ActionData/ProposeTransactionActionData");

const ProposeTransactionView = ({ updateDataOnGameView }) => {
  const popup = StaticGameData.popup;

  const playerData = StaticGameData.getPlayerData();
  const color = PlayerData.getPlayerColor(playerData);
  const allPlayersData = PlayerData.getOtherPlayersWithFaction(playerData);

  const data = ActionCommonData.getData(playerData);
  const transaction = ProposeTransactionActionData.getTransaction(playerData);
  const forcedFaction = popup.forcedFaction;
  if (forcedFaction && Transaction.getReceivingFaction(transaction) === null) {
    Transaction.setReceivingFaction(transaction, forcedFaction);
  }

  const navigationData = {
    buttonsData: [],
  };

  navigationData.buttonsData.push({
    text: "Back",
    callback: () => {
      Popup.goBack();
    },
  });
  navigationData.buttonsData.push({
    text: "Confirm",
    callback: async () => {
      if (Transaction.getReceivingFaction(transaction) === null) {
        UIMessage.displayInfoMessage(
          "No faction chosen",
          "You must choose a faction to propose the transaction to"
        );
      } else {
        await ActionCommonData.resolveClient(playerData);

        //await updateDataOnGameView();
        Popup.goBack();
      }
    },
  });

  const DisplayChooseTargetFaction = ({}) => {
    return (
      <div>
        <div>
          <span>Choose the faction you want to propose the transaction to</span>
        </div>
        <div>
          {allPlayersData.map((player, key) => (
            <div key={key}>
              <BaseButton
                onClick={() => {
                  Transaction.setReceivingFaction(
                    transaction,
                    player.faction.name
                  );
                  Popup.deActivateBlackPopup();
                }}
              >
                <FactionLogo factionName={player.faction.name}></FactionLogo>
              </BaseButton>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const DisplayTransaction = ({ transaction }) => {
    return <TransactionView transaction={transaction}></TransactionView>;
  };

  const DisplayTargetFactionButton = ({ player }) => {
    const isSelected =
      Transaction.getReceivingFaction(transaction) &&
      player.faction.name === Transaction.getReceivingFaction(transaction);

    const buttonType = isSelected ? "greyOut" : "white";

    return (
      <BaseButton
        type={buttonType}
        onClick={() => {
          if (isSelected) {
            Transaction.setReceivingFaction(transaction, null);
          } else {
            Transaction.setReceivingFaction(transaction, player.faction.name);
          }
          Popup.touch();
        }}
      >
        <div>
          <FactionLogo factionName={player.faction.name}></FactionLogo>
        </div>
      </BaseButton>
    );
  };

  const DisplayChooseItems = ({ type, factionReceiving = false }) => {
    const factionReceivingName = Transaction.getReceivingFaction(transaction);
    const faction = factionReceiving
      ? factionReceivingName
      : playerData.faction.name;

    const player = PlayerData.getPlayerDataFromFaction(playerData, faction);

    let items = player.items;

    const canGiveItem = (item, faction) => {
      const itemDesc = Item.getDescription(item);
      if (itemDesc.type === Item.TYPE_RELIC) {
        if (itemDesc.faction !== faction) {
          UIMessage.displayInfoMessage(
            "Relic are sacred !",
            faction +
              " can't give the relic " +
              itemDesc.text +
              ", because it is not the faction which produced this relic. Only the faction which produced this relic (" +
              itemDesc.faction +
              ") can give it to another faction."
          );
          return false;
        }
      }
      return true;
    };

    let selectedItems = [];
    let selectItem = null;
    let unSelectItem = null;
    if (factionReceiving) {
      selectedItems = Transaction.getItemsIn(transaction);
      selectItem = (item) => {
        if (!canGiveItem(item, faction)) return;
        Transaction.addItemIn(transaction, item);
        Popup.touch();
      };
      unSelectItem = (item) => {
        Transaction.removeItemIn(transaction, item);
        Popup.touch();
      };
    } else {
      selectedItems = Transaction.getItemsOut(transaction);
      selectItem = (item) => {
        if (!canGiveItem(item, faction)) return;
        Transaction.addItemOut(transaction, item);
        Popup.touch();
      };
      unSelectItem = (item) => {
        Transaction.removeItemOut(transaction, item);
        Popup.touch();
      };
    }
    if (type === Item.TYPE_ARTIFACT) {
      items = Item.getArtifactsFromItems(items);
      selectedItems = Item.getArtifactsFromItems(selectedItems);
    } else if (type === Item.TYPE_RELIC) {
      items = Item.getRelicsFromItems(items);
      selectedItems = Item.getRelicsFromItems(selectedItems);
    } else {
      throw new Error("DisplayChooseItems : Invalid item type");
    }
    /*items,
    displayItem,
    selectedItems,
    selectItem,
    unSelectItem,
    textSelected,
    textUnselected,
    textIfEmpty = "No items to display",
    lineLayout = false,*/

    return (
      <div>
        <SelectionListView
          items={items}
          displayItem={(item) => {
            return (
              <BaseButton
                onClick={() => {
                  Popup.addDynamicLayer(
                    <ItemDetailedView
                      playerId={PlayerData.getPlayerId(player)}
                      itemId={item.id}
                    ></ItemDetailedView>
                  );
                }}
              >
                {" "}
                <ItemView item={item}></ItemView>
              </BaseButton>
            );
          }}
          selectedItems={selectedItems}
          selectItem={selectItem}
          unSelectItem={unSelectItem}
        ></SelectionListView>
        <div>
          <BaseButton
            onClick={() => {
              Popup.deActivateBlackPopup();
            }}
          >
            Ok
          </BaseButton>
        </div>
      </div>
    );
  };

  const SelectionFactionTarget = () => {
    const otherPlayers = PlayerData.getOtherPlayersWithFaction(playerData);
    return (
      <div>
        {otherPlayers.map((player, key) => (
          <div key={key}>
            <DisplayTargetFactionButton
              player={player}
            ></DisplayTargetFactionButton>
          </div>
        ))}
      </div>
    );
  };

  const DisplayCreditSelection = () => {
    return (
      <div className="div-hor-center">
        <div style={{ marginRight: "1em", marginLeft: "1em" }}>
          {" "}
          <Logo logoName="credit" heightRem="2em"></Logo>
        </div>

        <BaseButton
          width="3em"
          onClick={() => {
            Transaction.modifyCreditBalance(transaction, -1);
            Popup.touch();
          }}
        >
          -
        </BaseButton>
        <BaseButton
          width="3em"
          onClick={() => {
            Transaction.modifyCreditBalance(transaction, +1);
            Popup.touch();
          }}
        >
          +
        </BaseButton>
      </div>
    );
  };
  const DisplaySummary = () => {
    const factionReceivingName = Transaction.getReceivingFaction(transaction);

    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <div style={{ minHeight: "10em" }}>
          <div>
            <div>
              <span>
                <FactionLogo
                  factionName={playerData.faction.name}
                ></FactionLogo>{" "}
                You will give :{" "}
              </span>{" "}
            </div>
            <div>
              <BaseButton
                onClick={() => {
                  Transaction.modifyCreditBalance(transaction, +1);
                  Popup.touch();
                }}
              >
                <Logo logoName="credit"></Logo>
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(
                    <DisplayChooseItems
                      type={Item.TYPE_ARTIFACT}
                      factionReceiving={false}
                    ></DisplayChooseItems>
                  );
                }}
              >
                Artifact
              </BaseButton>
              <BaseButton
                onClick={() => {
                  Popup.activateBlackPopup(
                    <DisplayChooseItems
                      type={Item.TYPE_RELIC}
                      factionReceiving={false}
                    ></DisplayChooseItems>
                  );
                }}
              >
                Relic
              </BaseButton>
            </div>

            <span>
              <TransactionItemsView
                transaction={transaction}
                itemIn={false}
              ></TransactionItemsView>
            </span>
          </div>
        </div>

        <div style={{ minHeight: "10em" }}>
          {" "}
          <div>
            <BaseButton
              onClick={() => {
                Popup.activateBlackPopup(
                  <DisplayChooseTargetFaction></DisplayChooseTargetFaction>
                );
              }}
            >
              {Transaction.getReceivingFaction(transaction) ? (
                <FactionLogo
                  factionName={Transaction.getReceivingFaction(transaction)}
                ></FactionLogo>
              ) : (
                <span> ? </span>
              )}
            </BaseButton>{" "}
            {Transaction.getReceivingFaction(transaction)} will give :
          </div>
          <div>
            <BaseButton
              onClick={() => {
                Transaction.modifyCreditBalance(transaction, -1);
                Popup.touch();
              }}
            >
              <Logo logoName="credit"></Logo>
            </BaseButton>
            {factionReceivingName && (
              <span>
                <BaseButton
                  onClick={() => {
                    Popup.activateBlackPopup(
                      <DisplayChooseItems
                        type={Item.TYPE_ARTIFACT}
                        factionReceiving={true}
                      ></DisplayChooseItems>
                    );
                  }}
                >
                  Artifact
                </BaseButton>
                <BaseButton
                  onClick={() => {
                    Popup.activateBlackPopup(
                      <DisplayChooseItems
                        type={Item.TYPE_RELIC}
                        factionReceiving={true}
                      ></DisplayChooseItems>
                    );
                  }}
                >
                  Relic
                </BaseButton>
              </span>
            )}
          </div>
          <span>
            <TransactionItemsView
              transaction={transaction}
              itemIn={true}
            ></TransactionItemsView>
          </span>
        </div>
      </div>
    );
  };

  const DisplayOpenIventory = () => {
    return (
      <div>
        <BaseButton onClick={() => {}}>Items</BaseButton>
      </div>
    );
  };

  const DisplayAutomaticallyAccepted = () => {
    if (Transaction.isAutomaticallyAccepted(transaction)) {
      return (
        <div>
          <span>
            This transaction will be automatically accepted and treated
            instantaneously, as you expect nothing in return.
          </span>
        </div>
      );
    }
  };

  return (
    <GameGlobalLayout showTitle={true}>
      <div
        className="text-box"
        style={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        {false && <DisplayCreditSelection></DisplayCreditSelection>}
        {false && <DisplayOpenIventory></DisplayOpenIventory>}
        <DisplaySummary></DisplaySummary>
        <DisplayAutomaticallyAccepted></DisplayAutomaticallyAccepted>
      </div>
      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ProposeTransactionView;
