import React, { useState } from "react";
import UnitImageView from "./UnitImageView.jsx";
import BaseButton from "../Utils/BaseButton.jsx";
import Logo from "../Utils/Logo.jsx";
import ResourceView from "../ManageSystem/ResourceView.jsx";
import ResourceBarView from "../Utils/ResourceBarView";
import FleetsSummaryView from "../ManageSystem/FleetsSummaryView";
import FactionLogo from "../Utils/FactionLogo";
import SpaceObjectImageView from "./SpaceObjectImageView.jsx";
import FleetLineView from "./FleetLineView.jsx";
import ProductionActionData from "../../Data/GameData/ActionData/ProductionActionData.js";
import BuildUnitActionData from "../../Data/GameData/ActionData/BuildUnitActionData.js";
import BuildStructureActionData from "../../Data/GameData/ActionData/BuildStructureActionData.js";
import DropActionData from "../../Data/GameData/ActionData/DropActionData.js";
import LiftActionData from "../../Data/GameData/ActionData/LiftActionData.js";
import InvasionActionData from "../../Data/GameData/ActionData/InvasionActionData.js";
import TransferActionData from "../../Data/GameData/ActionData/TransferActionData.js";
import ActionCommonData from "../../Data/GameData/ActionData/ActionCommonData.js";
import BuyActionData from "../../Data/GameData/ActionData/BuyActionData.js";
import PlanetBonusView from "./PlanetBonusView.jsx";
import PlanetBonus from "../../Data/GameData/Bonus/PlanetBonus.js";
import PlayerData from "../../Data/GameData/PlayerData/PlayerData.js";
import TechList from "../../Data/GameData/Technology/TechList.js";

const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const ImagesUrl = require("../../Data/Other/ImagesUrl.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const StaticGameData = require("../../Data/GameData/StaticGameData.js");
const LogAttachment = require("../../Data/GameData/Connection/LogAttachment.js");
const ActivationCA = require("../../ClientAction/ActivationCA.js");
const UIMessage = require("../../Data/GameData/Connection/UIMessage.js");
const SpaceObject = require("../../Data/GameData/MapData/SpaceObject.js");
const Rules = require("../../Data/GameData/Game/Rules.js");
const Map = require("../../Data/GameData/MapData/Map.js");
const Planet = require("../../Data/GameData/MapData/Planet.js");
const LogBook = require("../../Data/GameData/Connection/LogBook.js");
const Popup = require("../../Data/Other/Popup.js");
const Color = require("../../Common/Config/Colors.js");
const StartOfGameCA = require("../../ClientAction/StartOfGameCA.js");

const IMAGE_FACTION_LOGO_BASE = "/Images/Faction/";

const PlanetView = ({
  planet,
  children,
  onclick = () => {},
  calledFromActivation = false,
  showFleet = true,
  showStock = true,
  showPopRepartition = true,
  showLogs = false,
  showButtonPlanetName = true,
  showPlanetParameter = false,
  showGeneralInfo = false,
  showAction = false, //Put on true if you want to display the action button
  showSize = false,
  showBonus = true,
}) => {
  const playerData = StaticGameData.getPlayerData();

  const fleetEMSize = "1em";

  const DisplayLogs = () => {
    const combatLog = LogAttachment.getFullCombatLog(planet);
    const activityLog = LogAttachment.getFullActivityLog(planet);

    console.log("DisplayLogs activityLog", activityLog);

    return (
      <span>
        {(!LogBook.isEmpty(combatLog) || !LogBook.isEmpty(activityLog)) && (
          <BaseButton
            onClick={() => {
              Popup.addLayer({
                name: "ManageLogView",
                combatLog: combatLog,
                activityLog: activityLog,
              });
            }}
          >
            Logs
          </BaseButton>
        )}
      </span>
    );
  };
  const DisplaySize = ({}) => {
    const usedSize = Planet.getAmountUsedSize(planet);
    const size = planet.size;
    return (
      <span>
        {usedSize} / {size}
      </span>
    );
  };

  const DisplayPopRepartition = ({}) => {
    return (
      <span
        style={
          {
            //backgroundColor: "black",
            //borderRadius: "10px",
            //padding: "10px",
          }
        }
      >
        {false && planet.unassignedPop > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.unassignedPop}
              resourceType="population"
            ></ResourceBarView>
          </span>
        )}
        {planet.popOnScience > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.popOnScience}
              resourceType="popOnScience"
            ></ResourceBarView>
          </span>
        )}
        {planet.popOnEnergy > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.popOnEnergy}
              resourceType="popOnEnergy"
            ></ResourceBarView>
          </span>
        )}
        {planet.popOnMineral > 0 && (
          <span style={{ marginRight: "1em" }}>
            <ResourceBarView
              fontSize="1.5em"
              amount={planet.popOnMineral}
              resourceType="popOnMineral"
            ></ResourceBarView>
          </span>
        )}
      </span>
    );
  };

  const DisplayStock = ({ planet }) => {
    return (
      <span>
        <ResourceBarView
          resourceType="mineral"
          amount={planet.mineral}
          fontSize="1.5em"
        ></ResourceBarView>
      </span>
    );
  };

  const DisplayPlanet = ({ planet }) => {
    const arrayResources = [];
    let key = 1;
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_MINERAL}
      >
        {planet.mineral} + {planet.mineralEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SCIENCE}
      >
        + {planet.scienceEfficiency} / P
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_INFLUENCE}
      >
        + {planet.influence}
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_POPULATION}
      >
        {planet.population} + {planet.populationGrow}%
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_ENERGY}
      >
        + 0
      </ResourceView>
    );
    arrayResources.push(
      <ResourceView
        key={key++}
        heightRem="1em"
        resourceType={Rules.RESOURCE_TYPE_SIZE}
      >
        <span> </span>
        {planet.size}
      </ResourceView>
    );

    //console.log("DisplayPlanet ", planet);
    //console.log("DisplayPlanet planet.mineral", planet.mineral);

    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          textAlign: "left",
          //gap: "0",
          width: "100%",
          fontSize: "1.5rem",
        }}
      >
        {arrayResources}
      </div>
    );
  };

  const consultPlanet = () => {
    /*Popup.reset();
    Popup.addLayer({
      name: "ManageSystemView",
      system: Map.getSystemFromplanet(planet, playerData.map),
    });*/
    Popup.addLayer({
      name: "ManageObjectView",
      planet: planet,
    });
  };

  const DisplayPlanetGeneralInfo = ({}) => {
    return (
      <div>
        <div>Planet type : {planet.type}</div>
      </div>
    );
  };

  const DisplayPlanetParameters = ({ planet }) => {
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>
          Base Resources production :{" "}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            {" "}
            <Logo logoName="mineral"></Logo>
            <span> : {planet.mineralEfficiency} </span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span> : {planet.scienceEfficiency} </span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span> : {planet.energyEfficiency} </span>
          </div>
          <div>
            <Logo logoName="population"></Logo>
            <span> : {planet.populationEfficiency} </span>
          </div>
        </div>
        <div>
          <Logo logoName="build"></Logo>
          <span>
            {" "}
            {Planet.getConstructionValue(playerData, planet, true)} : this
            planet can build{" "}
            {Planet.getConstructionValue(playerData, planet, true)} units
          </span>
        </div>
      </div>
    );
  };

  const DisplayActualProduction = ({ planet }) => {
    if (!planet.faction) {
      return;
    }
    const planetPlayerData = PlayerData.getPlayerDataFromFaction(
      playerData,
      planet.faction
    );
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>
          <FactionLogo factionName={planet.faction}></FactionLogo> Production :{" "}
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            {" "}
            <Logo logoName="mineral"></Logo>
            <span>
              {" "}
              : {Planet.getMineralProduction(planetPlayerData, planet)}{" "}
            </span>
          </div>
          <div>
            <Logo logoName="science"></Logo>
            <span>
              {" "}
              : {Planet.getScienceProduction(planetPlayerData, planet)}{" "}
            </span>
          </div>
          <div>
            <Logo logoName="energy"></Logo>
            <span>
              {" "}
              : {Planet.getEnergyProduction(planetPlayerData, planet)}{" "}
            </span>
          </div>
          <div>
            <Logo logoName="population"></Logo>
            <span>
              {" "}
              : {Planet.getPopulationProduction(planetPlayerData, planet)}{" "}
            </span>
          </div>
        </div>
        <div>
          <Logo logoName="build"></Logo>
          <span>
            {" "}
            {Planet.getConstructionValue(planetPlayerData, planet, false)} :
            this planet can build{" "}
            {Planet.getConstructionValue(planetPlayerData, planet, false)} units
          </span>
        </div>
      </div>
    );
  };

  const DisplayActivationButton = () => {
    if (planet.faction !== playerData.faction.name) {
      return null;
    }
    return (
      <span>
        {" "}
        {planet.hasProduced && (
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already produced",
                "This planet already produced during this round. It will be able to produce again next round."
              );
            }}
            type="greyOut"
          >
            Resource Production
          </BaseButton>
        )}
        {!planet.hasProduced && (
          <BaseButton
            onClick={() => {
              ActivationCA.initialize_STEP_ACTIVATION_PRODUCTION(planet);
            }}
          >
            Resource Production
          </BaseButton>
        )}
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_STRUCTURE(planet);
          }}
        >
          Build Structures
        </BaseButton>
        {planet.hasBuiltUnits && (
          <BaseButton
            onClick={() => {
              UIMessage.displayInfoMessage(
                "Already Built Units",
                "This planet already built units during this round. It will be able to build units again next round."
              );
            }}
            type="greyOut"
          >
            Build Units
          </BaseButton>
        )}
        {!planet.hasBuiltUnits && (
          <BaseButton
            onClick={() => {
              ActivationCA.initialize_STEP_ACTIVATION_BUILD(planet);
            }}
          >
            Build Units
          </BaseButton>
        )}
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_TRANSFER(planet);
          }}
        >
          Transfer In
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_LIFT(planet);
          }}
        >
          Lift
        </BaseButton>
        <BaseButton
          onClick={() => {
            ActivationCA.initialize_STEP_ACTIVATION_DROP(planet);
          }}
        >
          Drop
        </BaseButton>
      </span>
    );
  };

  const DisplayWillBeInvade = () => {
    if (
      playerData.faction &&
      Planet.isFactionInvading(planet, playerData.faction.name)
    ) {
      return (
        <div
          style={{
            color: "red",
            backgroundColor: Color.COLOR_LOWLIGHT,
            textAlign: "center",
          }}
        >
          Will be invaded
        </div>
      );
    }
  };

  const Display2ColumnView = ({ planet }) => {
    return (
      <div
        //className={"div-hor-center"}
        style={{
          fontSize: "1em",
          display: "flex",
          //justifyContent: "center",
          alignItems: "center",
          //backgroundColor: "grey",
        }}
      >
        <div
          //className="div-fill"
          style={{ width: "20%", margin: "1em" }}
          /*onClick={() => {
            consultPlanet();
          }}*/
        >
          <planetView planet={planet}></planetView>
        </div>
        <div
          style={{
            //display: "flex",
            //flexDirection: "column",
            textAlign: "left",
            fontSize: "1em",
            lineHeight: "1",
            //backgroundColor: "yellow",
          }}
        >
          <div
            style={
              {
                //display: "flex",
                //alignItems: "center", // Align items vertically within the div
              }
            }
          >
            {showButtonPlanetName && (
              <div>
                <BaseButton
                  onClick={() => {
                    Popup.addLayer({
                      name: "ManageObjectView",
                      planet: planet,
                    });
                  }}
                >
                  {planet.name}
                </BaseButton>
              </div>
            )}
            {showGeneralInfo && (
              <DisplayPlanetGeneralInfo
                planet={planet}
              ></DisplayPlanetGeneralInfo>
            )}
            {showPopRepartition && (
              <DisplayPopRepartition planet={planet}></DisplayPopRepartition>
            )}

            <div>
              <span>
                {showStock && <DisplayStock planet={planet}></DisplayStock>}
              </span>
            </div>
          </div>
          <div>
            {showFleet && <DisplayFleets planet={planet}></DisplayFleets>}
          </div>
          <div>
            {showLogs && <DisplayLogs planet={planet}></DisplayLogs>}

            <DisplayWillBeInvade></DisplayWillBeInvade>
          </div>

          <div> {children}</div>
        </div>
      </div>
    );
  };

  //const DisplayObjectInformation

  // const planetFleet = Planet.getFleet(planet);

  const DisplayPlanetImage = ({}) => {
    return (
      <SpaceObjectImageView
        showFleet={false}
        spaceObject={planet}
        showBonus={false}
      ></SpaceObjectImageView>
    );
  };

  const DisplayFleets = ({}) => {
    const fleets = Planet.getFleets(planet);
    return (
      <div>
        {fleets.map((fleet, index) => {
          return (
            <div key={index} style={{ fontSize: "1em" }}>
              <FleetLineView fleet={fleet}></FleetLineView>
            </div>
          );
        })}
      </div>
    );
  };

  const DisplayBonus = ({}) => {
    return (
      <div>
        <div style={{ textDecoration: "underline" }}>Planet Bonuses</div>
        {planet.bonus.map((bonus, index) => {
          const bonusDesc = PlanetBonus.getBonus(bonus);
          return (
            <div key={index}>
              <PlanetBonusView bonusName={bonus}></PlanetBonusView>
              <span> : {bonusDesc.effect}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      style={{
        position: "relative",
        minHeight: "256px",
        textAlign: "center",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          textAlign: "center",
          marginBottom: "2em",
          width: "auto",
        }}
      >
        {showButtonPlanetName && (
          <div style={{ marginBottom: "1em" }}>
            <BaseButton
              onClick={() => {
                Popup.addLayer({
                  name: "ManageObjectView",
                  spaceObject: planet,
                });
              }}
            >
              Planet : {planet.name}
            </BaseButton>
          </div>
        )}
        {showAction && (
          <div style={{ marginBottom: "1em" }}>
            <BaseButton
              type="action"
              onClick={() => {
                const productionButtonStyle = planet.hasProduced
                  ? "greyOut"
                  : "navigation";
                const buildUnitsButtonStyle = planet.hasBuiltUnits
                  ? "greyOut"
                  : "navigation";
                const buildStructureButtonStyle = planet.hasBuiltStructure
                  ? "greyOut"
                  : "navigation";
                const colonizationPossible =
                  playerData.colonists > 0 ? true : false;
                const planetControlledByPlayer =
                  planet.faction === playerData.faction.name;
                const planetIsAlreadyInvaded = Planet.isFactionInvading(
                  planet,
                  playerData.faction.name
                );

                const actionButtons = [];
                if (colonizationPossible) {
                  actionButtons.push(
                    <BaseButton
                      key="Colonize"
                      onClick={() => {
                        StartOfGameCA.resolve_STEP_START_OF_GAME_COLONIZE({
                          spaceObjectName: planet.name,
                        });
                      }}
                    >
                      Colonize
                    </BaseButton>
                  );
                }
                if (planetControlledByPlayer) {
                  actionButtons.push(
                    <BaseButton
                      key="Produce Resources"
                      onClick={() => {
                        if (planet.hasProduced) {
                          Popup.removeActionSelectionAttributes();
                          UIMessage.displayInfoMessage(
                            "Already produced",
                            "This planet already produced during this round. It will be able to produce again next round."
                          );
                        } else {
                          ProductionActionData.prepareStep(playerData, planet);
                        }
                      }}
                      type={productionButtonStyle}
                    >
                      <Logo logoName="mineral"></Logo>
                      <Logo logoName="science"></Logo>
                      <Logo logoName="energy"></Logo>
                      <Logo logoName="population"></Logo> Produce Resources
                    </BaseButton>,
                    <BaseButton
                      key="Build Structures"
                      onClick={() => {
                        if (planet.hasBuiltStructure) {
                          Popup.removeActionSelectionAttributes();
                          UIMessage.displayInfoMessage(
                            "Already built structures",
                            "This planet already built structures during this round. It will be able to build structures again next round."
                          );
                        } else {
                          BuildStructureActionData.prepare(playerData, planet);
                        }
                      }}
                      type={buildStructureButtonStyle}
                    >
                      Build Structures
                    </BaseButton>,
                    <BaseButton
                      key="Build Units"
                      onClick={() => {
                        if (planet.hasBuiltUnits) {
                          Popup.removeActionSelectionAttributes();
                          UIMessage.displayInfoMessage(
                            "Already built units",
                            "This planet already built units during this round. It will be able to build units again next round."
                          );
                        } else {
                          BuildUnitActionData.prepare(playerData, planet);
                        }
                      }}
                      type={buildUnitsButtonStyle}
                    >
                      Build Units
                    </BaseButton>,
                    <BaseButton
                      key="Drop"
                      onClick={() => {
                        DropActionData.prepare(playerData, planet);
                      }}
                    >
                      Drop
                    </BaseButton>,
                    <BaseButton
                      key="Lift"
                      onClick={() => {
                        LiftActionData.prepare(playerData, planet);
                      }}
                    >
                      Lift
                    </BaseButton>,
                    <BaseButton
                      key="Transfer"
                      onClick={() => {
                        TransferActionData.prepare(playerData, planet);
                      }}
                    >
                      Transfer
                    </BaseButton>,
                    <BaseButton
                      key="Lift"
                      onClick={() => {
                        LiftActionData.prepare(playerData, planet);
                      }}
                    >
                      Lift
                    </BaseButton>,
                    <BaseButton
                      key="Buy"
                      onClick={() => {
                        BuyActionData.prepare(playerData, planet);
                      }}
                    >
                      Buy Resources
                    </BaseButton>
                  );
                } else {
                  if (!planetIsAlreadyInvaded) {
                    actionButtons.push(
                      <BaseButton
                        key="Invade"
                        onClick={() => {
                          InvasionActionData.prepare(playerData, planet);
                          ActionCommonData.resolveClient(playerData);
                          Popup.removeActionSelectionAttributes();
                        }}
                      >
                        Invade
                      </BaseButton>
                    );
                  } else {
                    actionButtons.push(
                      <BaseButton
                        key="Invade"
                        onClick={() => {
                          InvasionActionData.prepare(playerData, planet, true);
                          ActionCommonData.resolveClient(playerData);
                          Popup.removeActionSelectionAttributes();
                        }}
                      >
                        Cancel Invasion
                      </BaseButton>
                    );
                  }
                  actionButtons.push(
                    <BaseButton
                      key="Lift"
                      onClick={() => {
                        LiftActionData.prepare(playerData, planet);
                      }}
                    >
                      Lift
                    </BaseButton>
                  );
                }

                Popup.addActionSelectionAttributes(actionButtons);
              }}
            >
              Planet Action
            </BaseButton>
          </div>
        )}
        <div style={{ marginBottom: "1em" }}>
          <SpaceObjectImageView
            spaceObject={planet}
            showFleet={false}
            showBonus={false}
            maxWidth={128}
            maxHeight={128}
          ></SpaceObjectImageView>
          <div style={{ marginTop: "1em" }}></div>
          <div style={{ fontSize: fleetEMSize }}>
            {" "}
            <DisplayFleets></DisplayFleets>
          </div>
        </div>
        {showGeneralInfo && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayPlanetGeneralInfo></DisplayPlanetGeneralInfo>
          </div>
        )}
        <div style={{ marginBottom: "1em" }}>
          {" "}
          <DisplayPopRepartition></DisplayPopRepartition>
        </div>
        {showStock && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayStock planet={planet}></DisplayStock>
          </div>
        )}
        {showLogs && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayLogs planet={planet}></DisplayLogs>
          </div>
        )}
        <div style={{ marginBottom: "1em" }}>
          <DisplayWillBeInvade></DisplayWillBeInvade>
        </div>{" "}
        {showPlanetParameter && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayPlanetParameters planet={planet}></DisplayPlanetParameters>
          </div>
        )}
        {showSize && (
          <div style={{ marginBottom: "1em" }}>
            <DisplaySize></DisplaySize>{" "}
          </div>
        )}
        {children}
        {planet.type === "Volcanic" && (
          <div style={{ marginBottom: "1em", color: "red" }}>
            The technology {TechList.TECH.thermalSuits.name} is required to
            place units on that planet.
          </div>
        )}{" "}
        {showBonus && planet.bonus.length > 0 && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayBonus></DisplayBonus>
          </div>
        )}
        {showPlanetParameter && (
          <div style={{ marginBottom: "1em" }}>
            <DisplayActualProduction planet={planet}></DisplayActualProduction>
          </div>
        )}
      </div>

      {false && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            width: "256px",
            height: "256px",
            transform: "translate(-50%, 0)",
            zIndex: -20,
          }}
        >
          <SpaceObjectImageView
            spaceObject={planet}
            showFleet={false}
            showBonus={false}
          ></SpaceObjectImageView>
        </div>
      )}
    </div>
  );
};

export default PlanetView;
