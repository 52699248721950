import React, { useState, useEffect } from "react";
import "../../CSS/Menu.css";
import BaseButton from "../Utils/BaseButton.jsx";
import Popup from "../../Data/Other/Popup.js";
import SpaceObjectSummaryView from "./SpaceObjectSummaryView.jsx";
import NavigationView from "../NavigationView.jsx";
import GameGlobalLayout from "../GameGlobalLayout.jsx";
import TitleView from "../TitleView.jsx";
import StaticGameData from "../../Data/GameData/StaticGameData.js";
import PlanetViewActivation from "./ActivationView/Objects/PlanetViewActivation.jsx";
import PlanetView from "../Object/PlanetView.jsx";
import SystemLogCombatView from "../LogViews/SystemLogCombatView.jsx";

const System = require("../../Data/GameData/MapData/System.js");
const CustomChars = require("../../Common/Chars/CustomChars.js");
const Color = require("../../Common/Config/Colors.js");
const Fleet = require("../../Data/GameData/MapData/Fleet.js");
const Faction = require("../../Data/GameData/PlayerData/Faction.js");
const CustomMath = require("../../Common/Math/CustomMath.js");
const Request = require("../../Common/Requests/Request.js");
const Map = require("../../Data/GameData/MapData/Map.js");

const ManagePlanetView = ({ planet }) => {
  const playerData = StaticGameData.getPlayerData();

  const navigationData = {
    buttonsData: [{ text: "Back", callback: () => Popup.goBack() }],
  };

  const DisplayPlanet = () => {
    return (
      <PlanetView
        planet={planet}
        showButtonPlanetName={false}
        showPlanetParameter={true}
        showGeneralInfo={true}
        showBonus={true}
      ></PlanetView>
    );
  };

  const DisplayCombatLogs = () => {
    const system = Map.getSystemFromSpaceObjectName(
      playerData.map,
      planet.name
    );
    const combatList = System.getAllCombatKeys(system);

    if (combatList.length === 0) {
      return;
    }
    return (
      <span style={{ textAlign: "center" }}>
        <BaseButton
          onClick={() => {
            Popup.addDynamicLayer(
              <SystemLogCombatView
                system={system}
                backCallback={() => {
                  Popup.goBack();
                }}
              ></SystemLogCombatView>
            );
          }}
        >
          Combats
        </BaseButton>
      </span>
    );
  };

  return (
    <GameGlobalLayout backgroundType="shadow">
      <div className="text-box">
        {" "}
        <DisplayPlanet></DisplayPlanet>
        <DisplayCombatLogs></DisplayCombatLogs>
      </div>

      <NavigationView navigationData={navigationData}></NavigationView>
    </GameGlobalLayout>
  );
};

export default ManagePlanetView;
