import React, { useEffect } from "react";
import { useRenderContextMapZoom } from "./Hooks/RenderContextMapZoom"; // Updated import
import BaseImage from "./BaseImage"; // Import the BaseImage component
import StaticGameData from "../../Data/GameData/StaticGameData";

const BaseImageMapZoom = ({
  style,
  src,
  alt,
  width,
  height,
  zoomTreshold = 4,
}) => {
  const { forceRenderValue } = useRenderContextMapZoom() || {
    forceRenderValue: -1,
  }; //ahrdcode forceRenderValue when outside of the wrapper renderContextMapZoom

  const disableZoomRerender =
    StaticGameData.popup.name === "MapView" ? false : true;
  // Effect that runs when forceRenderValue changes, unless disabled
  useEffect(() => {
    if (!disableZoomRerender) {
      /*console.log(
        "BaseImageMapZoom force rerendered due to zoom change:",
        forceRenderValue
      );*/
      // Any additional logic related to zoom changes can be placed here
    }
  }, [forceRenderValue, disableZoomRerender]);

  // Image style, spreading any passed-in styles
  const imageStyle = {
    ...style,
  };

  const forceLowRes =
    forceRenderValue < zoomTreshold && forceRenderValue !== -1 ? true : false;

  //console.log("forceLowRes", forceLowRes);

  return (
    <BaseImage
      style={imageStyle}
      src={src}
      alt={alt}
      width={width}
      height={height}
      forceLowRes={forceLowRes}
    />
  );
};

export default BaseImageMapZoom;
