import { useState, useEffect } from "react";
import ChatStaticData from "../../Data/ChatData/ChatStaticData"; // Adjust the import path as necessary

const useChatData = (onDataFetched) => {
  const [chatData, setChatData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchChatData = async () => {
    try {
      console.log("Fetching chat data...");
      await ChatStaticData.loadChatData();
      setChatData(ChatStaticData.chatData);
      onDataFetched(ChatStaticData.chatData);
      console.log("Chat data fetched and set.");
    } catch (error) {
      console.error("Error fetching chat data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatData();

    const intervalId = setInterval(() => {
      fetchChatData();
    }, 5000); // Fetch data every 10 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { chatData, loading };
};

export default useChatData;
